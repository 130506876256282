
.text-link{
  cursor: pointer;
  color: $gold;
  background-color: transparent;
  position: relative;
  font-weight: 300;

  &:not(.no-icon):after {
    content: '\f178';
    font-family: FontAwesome;
    margin-left: 5px;
  }

  &:hover, &.active{
    color: $dark-blue;

    &.hover-white {
      color: white;
    }
  }

  &.white {
    color: white;

    &:hover, &.active {
      color: $dark-blue;
    }
  }

  &.gold {
    color: $gold;

    &:hover, &.active{
      color: $white;

    }
  }

  &.blue {
    color: $dark-blue;

    &:hover, &.active{
      color: $white;
    }
  }


  &.dark {
    color: $charcoal;

    &:hover, &.active{
      color: $gold;
    }
  }

  &.uppercase{
    text-transform: uppercase;
  }

  &.bold {
    font-weight: 600;
  }

  &.underline {
    text-decoration: underline;
  }
}