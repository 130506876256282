.d-none {display: none !important;}

.d-flex {display: flex!important;}
.d-inline-flex {display: inline-flex!important;}
.flex-grow{flex-grow: 1;}
.flex-basis{flex-basis: 0;}
.flex-row {flex-direction: row;}
.flex-column {flex-direction: column;}
.flex-row-reverse {flex-direction: row-reverse;}
.flex-column-reverse {flex-direction: column-reverse;}
.flex-wrap {flex-wrap: wrap;}
.flex-nowrap {flex-wrap: nowrap;}
.flex-wrap-reverse {flex-wrap: wrap-reverse;}
.flex-none {flex: none;}

.flex-column  {  flex: 1 0 0;  }
.flex-column-1  {  flex: 1 0 8.33%;   }
.flex-column-2  {  flex: 1 0 16.66%;  }
.flex-column-3  {  flex: 1 0 25%;     }
.flex-column-4  {  flex: 1 0 33.33%;  }
.flex-column-5  {  flex: 1 0 41.66%;  }
.flex-column-6  {  flex: 1 0 50%;     }
.flex-column-7  {  flex: 1 0 58.33%;  }
.flex-column-8  {  flex: 1 0 66.66%;  }
.flex-column-9  {  flex: 1 0 75%;     }
.flex-column-10 {  flex: 1 0 83.33%;  }
.flex-column-11 {  flex: 1 0 91.66%;  }
.flex-column-12 {  flex: 1 0 100%;    }

.flex-gutters { gap: 30px; }

.align-items-start {align-items: flex-start;}
.align-items-end {align-items: flex-end;}
.align-items-center {align-items: center;}
.align-items-baseline {align-items: baseline;}
.align-items-stretch {align-items: stretch;}
.align-content-start {align-content: flex-start;}
.align-content-end {align-content: flex-end;}
.align-content-center {align-content: center;}
.align-content-between {align-content: space-between;}
.align-content-around {align-content: space-around;}
.align-content-stretch {align-content: stretch;}
.align-self-auto {align-self: auto;}
.align-self-start {align-self: flex-start;}
.align-self-end {align-self: flex-end;}
.align-self-center {align-self: center;}
.align-self-baseline {align-self: baseline;}
.align-self-stretch {align-self: stretch;}

.justify-content-start {justify-content: flex-start;}
.justify-content-end {justify-content: flex-end;}
.justify-content-center {justify-content: center;}
.justify-content-between {justify-content: space-between;}
.justify-content-around {justify-content: space-around;}

.order-1 { order: 1}
.order-2 { order: 2}
.order-3 { order: 3}
.order-4 { order: 4}
.order-5 { order: 5}

@media#{$medium-up}{
  .d-md-flex {display: flex!important;}
  .d-md-inline-flex {display: inline-flex!important;}

  .d-md-flex {display: flex;}
  .d-md-inline-flex {display: inline-flex;}

  .flex-md-row {flex-direction: row;}
  .flex-md-column {flex-direction: column;}
  .flex-md-row-reverse {flex-direction: row-reverse;}
  .flex-md-column-reverse {flex-direction: column-reverse;}
  .flex-md-wrap {flex-wrap: wrap;}
  .flex-md-nowrap {flex-wrap: nowrap;}
  .flex-md-wrap-reverse {flex-wrap: wrap-reverse;}
  .flex-md-none {flex: none;}

  .align-items-md-start {align-items: flex-start;}
  .align-items-md-end {align-items: flex-end;}
  .align-items-md-center {align-items: center;}
  .align-items-md-baseline {align-items: baseline;}
  .align-items-md-stretch {align-items: stretch;}
  .align-content-md-start {align-content: flex-start;}
  .align-content-md-end {align-content: flex-end;}
  .align-content-md-center {align-content: center;}
  .align-content-md-between {align-content: space-between;}
  .align-content-md-around {align-content: space-around;}
  .align-content-md-stretch {align-content: stretch;}
  .align-self-md-auto {align-self: auto;}
  .align-self-md-start {align-self: flex-start;}
  .align-self-md-end {align-self: flex-end;}
  .align-self-md-center {align-self: center;}
  .align-self-md-baseline {align-self: baseline;}
  .align-self-md-stretch {align-self: stretch;}

  .justify-content-md-start {justify-content: flex-start;}
  .justify-content-md-end {justify-content: flex-end;}
  .justify-content-md-center {justify-content: center;}
  .justify-content-md-between {justify-content: space-between;}
  .justify-content-md-around {justify-content: space-around;}

  .order-md-1 { order: 1}
  .order-md-2 { order: 2}
  .order-md-3 { order: 3}
  .order-md-4 { order: 4}
  .order-md-5 { order: 5}
}

@media#{$large-up}{
  .d-lg-flex {display: flex!important;}
  .d-lg-inline-flex {display: inline-flex!important;}

  .d-lg-flex {display: flex;}
  .d-lg-inline-flex {display: inline-flex;}

  .flex-lg-row {flex-direction: row;}
  .flex-lg-column {flex-direction: column;}
  .flex-lg-row-reverse {flex-direction: row-reverse;}
  .flex-lg-column-reverse {flex-direction: column-reverse;}
  .flex-lg-wrap {flex-wrap: wrap;}
  .flex-lg-nowrap {flex-wrap: nowrap;}
  .flex-lg-wrap-reverse {flex-wrap: wrap-reverse;}
  .flex-lg-none {flex: none;}

  .align-items-lg-start {align-items: flex-start;}
  .align-items-lg-end {align-items: flex-end;}
  .align-items-lg-center {align-items: center;}
  .align-items-lg-baseline {align-items: baseline;}
  .align-items-lg-stretch {align-items: stretch;}
  .align-content-lg-start {align-content: flex-start;}
  .align-content-lg-end {align-content: flex-end;}
  .align-content-lg-center {align-content: center;}
  .align-content-lg-between {align-content: space-between;}
  .align-content-lg-around {align-content: space-around;}
  .align-content-lg-stretch {align-content: stretch;}
  .align-self-lg-auto {align-self: auto;}
  .align-self-lg-start {align-self: flex-start;}
  .align-self-lg-end {align-self: flex-end;}
  .align-self-lg-center {align-self: center;}
  .align-self-lg-baseline {align-self: baseline;}
  .align-self-lg-stretch {align-self: stretch;}

  .justify-content-lg-start {justify-content: flex-start;}
  .justify-content-lg-end {justify-content: flex-end;}
  .justify-content-lg-center {justify-content: center;}
  .justify-content-lg-between {justify-content: space-between;}
  .justify-content-lg-around {justify-content: space-around;}

  .order-lg-1 { order: 1}
  .order-lg-2 { order: 2}
  .order-lg-3 { order: 3}
  .order-lg-4 { order: 4}
  .order-lg-5 { order: 5}
}