.very-light-grey{ background: $very-light-gray; }
.light-grey{ background: $light-gray; }

.white{ background: $white; }
.black{
    background: black;
    color: white;

    * {
        color: white;
    }
}

// Blue horizons page
.logo-bg {
    background-image: url("/images/rol_bh_bg.png");
    background-position: 50% top;
    background-repeat: no-repeat;
    background-size: 80rem;

    &.pos-top {
        background-position: 50% top;
    }

    &.pos-bottom {
        background-position: 50% bottom;
    }
}

section.dark-grey {
    background-color: #141414;
    color: white;

    * {
        color: white;
    }
}

.margin-top{ margin-top: 1rem; }

.double-margin-top{

    // Used to move preceding divider to sit at the top of a <section>

    @media #{$small-only}{
        margin-top: 1.5rem;
    }

    @media #{$medium-up}{
        margin-top: 2rem;
    }
}

.margin-bottom{ margin-bottom: 1rem; }
.padding-top{ padding-top: 1rem; }
.padding-bottom{ padding-bottom: 1rem; }
.padding-left{ padding-left: 1rem; }
.padding-right{ padding-right: 1rem; }
.no-margin{margin: 0};
.margin-right{ margin-right: 1rem; }

@media #{$large-up} {
    .margin-large-bottom{ margin-bottom: 1rem; }
    .padding-large-top{ padding-top: 1rem; }
    .padding-large-bottom{ padding-bottom: 1rem; }
    .padding-large-left{ padding-left: 1rem; }
    .padding-large-right{ padding-right: 1rem; }
}

@media #{$medium-up} {
    .margin-medium-bottom{ margin-bottom: 1rem; }
    .margin-medium-top{ margin-top: 1rem; }
    .padding-medium-top{ padding-top: 1rem; }
    .padding-medium-bottom{ padding-bottom: 1rem; }
    .padding-medium-left{ padding-left: 1rem; }
    .padding-medium-right{ padding-right: 1rem; }
}

.padding-top-half{ padding-top: 0.5rem; }
.margin-bottom-half{ margin-bottom: 0.5rem; }

.relative{
    position: relative;
}
.bring-to-front{
    position: relative;
    z-index: 15;
}
.push-to-back{
    position: relative;
    z-index: 0;
}

.small-pull-up{
    @media#{$small-only}{
        position: relative;
        margin-top: rem-calc(-50);
    }
}
.pull-up-large {
    @media#{$large-up}{
        position: relative;
        margin-top: rem-calc(-100);
    }
}
.pull-up-medium {
    @media#{$medium-up}{
        position: relative;
        margin-top: rem-calc(-100);
    }
}
.pull-up{
    position: relative;
    margin-top: rem-calc(-30);
    @media#{$large-up}{
        margin-top: rem-calc(-100);
    }
}

.equal-height{
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    display: flex;
    display: -webkit-flex;
    .equal-height-item{
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        display: flex;
        display: -webkit-flex;
    }
}

h1{
    margin: 1rem 0;
    &.no-hr{
        margin: 1rem 0 2rem 0;
    }

    &.gold {
        color: $gold;
    }
}

.sans-serif {
    font-family: $body-font-family
}
.font-normal {
    text-decoration: none;
    font-style: normal;
}
.bold {
    font-weight: bold;
}

p{
    &.small {
        font-size: 0.8rem;
    }
    &.medium{
        font-size: 120%;
    }
    &.large{
        font-size: 140%;
    }
    &.thin{
        font-weight: 300;
    }
    &.gold{
        color: $gold;
    }
    &.navy{
        color: $navy;
    }
    &.semi-bold{
        font-weight: 600;
    }
    
    span.italic-serif {
        font-family: $header-font-family;
        font-style: italic;
    }

}

.divider-container{
    float: left;
    width: 100%;
    margin: 1rem 0;

    &.pull-up{
        margin-top: -1em;

        @media #{$small-only}{
            margin-top: -0.5em;
        }
    }

    &.centered-image{
        position: relative;
        img{
            position: absolute;
            margin-left: rem-calc(-18);
            top: rem-calc(-2);
            left: 50%;
            @media#{$small-only}{
                top: rem-calc(-9);
            }
        }
    }
    
    &.overlaps-top-section {
        margin: -1rem 0 3rem; // used when following the coloured section to remove the top margin
    }
}

.grey-divider,
.black-divider{
    margin: 1rem 0;
    float: left;
    width: 100%;
    max-width: 100%;
    @media#{$small-only}{
        margin: 0.5rem 0;
    }
}

.divide-line-right {
    display: block;
    width: rem-calc(1);
    background: $medium-gray;
    position: absolute;
    right: 0rem;
    height: 100%;
    top: 0;
}
.black-divider{ border-color: $black; }
.white-divider{ border-color: $white; }
.grey-divider{ border-color: $medium-gray; }

.slideShowBtn {
    text-align: right;
    color: $white;
    display: block;
    font-weight: 500;
    text-transform: uppercase;
    transition: all 200ms ease-in-out;
     
     &:hover {
         color: $gold;
     }   
}

.section-heading{
    padding-top: 3rem;
    hr{
        border-color: $black;
        width: 10%;

        &.full-width{
            width: 100%;
        }
    }
}

/*.large-image-with-text,
.image-with-text {
    .inner-container {
        position: relative;
        overflow: hidden;
        .info {
            @include linear-gradient($transparent, rgba($black, 0.65));
            position: absolute;
            bottom: 0;
            padding: 1.5rem;
            width: 100%;
            @media#{$large-up}{
                padding: 3rem 1rem 1rem 1rem;
            }

            @media screen and (min-device-width: 640px) and (max-device-width: 815px) {
                padding: 0.5rem;
            }

            p,
            h2,
            h3,
            h4 {
                color: $white;
            }
            p {
                margin-bottom: 0;
                
                &.date {
                    font-family: $header-font-family;
                    font-style: italic;
                    color: $white;
                }
            }
            hr {
                border-color: $white;
                margin: 1rem 0;
                width: 50%;
            }
        }
        
        
        img {
            width: 100%;
        }
    }
    &.centered {
       .inner-container {
           bottom: 0;
           position: relative;
           &:before {
               content: " ";
               z-index: 10;
               display: block;
               position: absolute;
               height: 100%;
               top: 0;
               left: 0;
               right: 0;
               background: rgba(0, 0, 0, 0.25);
           }
           .info {
               @include breakpoint(small only) {
                   transform: none;
                   top: 0;
                   padding: 5% rem-calc(20);
               }
               padding: 8% rem-calc(100);
               background: none;
               top: 50%;
               transform: translateY(-50%);
               z-index: 10;
               hr {
                   margin: 0 auto;
               }
               p {
                   font-size: 120%;
               }
               @include breakpoint(small only) {
                    p {
                        font-size: 100%;
                    }
               }
           }
       }
    }

    &.featured-blog-post-hero {
        .inner-container {
            .info {
                hr {
                    margin: 0.1rem auto 0.5rem;
                }
            }
        }
    }    
}*/

.plain-text{
    .info{
        p,
        h2,
        h3,
        h4{
            color: $navy;
        }
        hr{
            // border-color: $navy;
            width: 25%;
        }
    }
}
.fancy-list-container{
    h4 {
        color: white;
    }
    .fancy-list{
        margin: 0;
        list-style: none;
        
        li {
             margin-bottom: 1rem;
             text-align: center;
             position: relative;
        }

        li + li {
            padding-top: 2rem;
        }
        
        li + li:before {
          position: absolute;
          left: 0;
          right: 0;
          top: -7px;
          text-align: center;
          content: url('/images/fancy-list-icn.png');
        }
    }
}
.cms-content-block {
    h1, h2, h3, h4, h5, h6 {
        margin-bottom: 1rem;
        margin-top: 2rem;
    }
}
ul.bare-list{
    list-style: none;
    margin-left: 0;
    margin-top: 1rem;
}
.connect-with-rol{
    margin: 0 0 2rem;
    display: block;
    text-align: center;

    @media#{$large-up}{
        text-align: left;
    }

    li {
        display: inline-block;
        line-height: 35px;
        height: 35px;
        margin: 0;

        & + & { margin: 0 }
    }

    a.social-icon{
            @include rounded-corners(50%);
            display: inline-block;
            width: rem-calc(35);
            height: rem-calc(35);
            color: $white;
            position: relative;
            text-align: center;
            margin: 3px;

            &.facebook {
                background: $facebook;

                &:hover {
                    background: darken($facebook, 15);
                }
            }
            &.twitter {
                background: $twitter;

                &:hover {
                    background: darken($twitter, 15);
                }
            }
            &.youtube {
                background: $youtube;

                &:hover {
                    background: darken($youtube, 15);
                }
            }
            &.pinterest {
                background: $pinterest;

                &:hover {
                    background: darken($pinterest, 15);
                }
            }
            &.linkedin {
                background: #0077B5;

                &:hover {
                    background: darken(#0077B5, 15);
                }
            }
            &.google-plus {
                background: $google-plus;

                &:hover {
                    background: darken($google-plus, 15);
                }
            }
            &.instagram {
                background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
            }
            i{
                width: 100%;
                left: 0;
                @include vertical-align($position: absolute);
            }
        }
}

.fieldset {
    border: none;
    padding: 0;
    margin: 0.65rem 0;
}

@media#{$small-only}{
    .small-text-left {
        text-align: left !important;
    }
}

.slick-list {
    .slick-loading & {
        background: none;
    }
}
@media#{$large-up}{
    [class*="large-up-"] {
        &.text-center {
            > .column {
                float: none;
                display: inline-table;
            }
        }
    }
}

.promoted-content-row h2{
    margin-bottom:1rem;
}

.slick-prev,
.slick-next {
    z-index: 99;
}

.content-area{
    hr{
        border: none;
        height: 2rem;
        margin: 0;
        background: url("/images/fancy-list-icn.png") center center no-repeat;
        margin-bottom: 1rem;
        width: 100%
    }
    a{
        color: $gold;
        &:hover{
            color: darken($gold, 7%);
        }
    }
}

.whats-included-list{
    .medium-6{
        ul.fancy-list{
            padding: 0 1.5rem
        }
    }
}

.cruise-partners-container{
    margin-bottom: 3rem;
}

.has-tip{
    display: block;
}

.cta-link-space{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    img{
        margin-right: 7px;
        display: block;
    }
    span{
        text-align: left;
        display: block;

        @media only screen and (max-width: 470px)  {
            max-width: 75px;
        }
    }
}

h2{
    &.included.text-center{
        small{
            font-size: 1.2rem;
            color: #38424f;
            line-height: 24px;
            display: inline-block;
            position: absolute;
            margin-left: 4px;
        }
    }
}

.fancylist-list{
    ul{
        list-style: none;
        li {
            font-size: 1.2rem;
            color: $light-navy;
            margin-bottom: 1rem;
            margin-top: 1rem;
            position: relative;
            &:before{
                content: '';
                height: 22px;
                width: 17px;
                background: url('/images/fancy-list-icn.png');
                margin-right: 6px;
                display: inline-block;
                position: relative;
                top: 4px;
            }
        }
    }
}

.search-wrap {
    float: left;
    padding: 1px;

    .input {
        height: 27px !important;
        border: 1px solid #CF9D63;
        background: #fff;
        display: inline-block;
        float:left;
        width: calc(100% - 27px);
        font-size: 12px;
        margin: 0;
        transition: all 500ms;
    }

    .submit {
        border: 1px solid #CF9D63;
        background-color: #CF9D63;
        color: rgba(#CF9D63, 0%);
        width: 27px !important;
        height: 27px !important;
        display: inline-block;
        float:right;
        padding: 0;
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAANCAYAAABy6+R8AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RDU0NDVDNjI0MUE4MTFFMTk3OURDRDgxOTNEMUU3NTYiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MjlFQzUyODQ0MUIyMTFFMTk3OURDRDgxOTNEMUU3NTYiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpENTQ0NUM2MDQxQTgxMUUxOTc5RENEODE5M0QxRTc1NiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpENTQ0NUM2MTQxQTgxMUUxOTc5RENEODE5M0QxRTc1NiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuhQNCgAAABuSURBVHjaYmCAgv///zcA8fv/CABiNzDgAkDJ8/9xg/PYNDQgmRyAJB6AZHMDuiaYRAAWAwNgBqJLgAEep2PIMzGQAUCaPsCcgs15UOYHqgSEAJYgf4/GL8DlYayRC9KAM77wASSX0Ffje4AAAwDkqAbz4euqrgAAAABJRU5ErkJggg==");
        background-position: 50% 50%;
        background-repeat: no-repeat;
        transition: all 500ms;
    }

    .submit:hover {
        background-color: $dark-blue;
        border: 1px solid $dark-blue;
    }

    .submit:hover + .input {
        border: 1px solid $dark-blue;
    }


}

.heading-section {
    ul,ol {
        list-style-position: inside;
        li{
            font-size: 18px;
            color: #748698;
            line-height: 25px;
        }
    }
    ul {
        list-style: disc;
        list-style-position: inside;
    }
}

// Part payment stuff

.reveal {
    #modalTitle {
        text-align: center;
        margin-top: 2rem;
    }
    p.lead{
        text-align: center;
    }
    p.payment_selecter{
        text-align: center;
        margin-bottom: 0.25em;
    }
    .pay_amount_hide {
        text-align: center;
    }
    .amount_and_continue {
        text-align: center;
    }
    input.pay_amount_hide {
        width:30%;
        padding:0;
        margin:0;
        vertical-align: top;
        height: 2.85rem;
    }
}
.error_area {
    color: red;
}
.selectedAddress {
    display: none;
}
.end .form-error.is-visible {
    margin:10px 0 0 0;
}
