.free-telephone {
    min-width: 250px;
    line-height: 1;
    color: $black;
    padding: 0 0.5rem;

    p {
        margin: 0;
    }

    &__number {
        font-weight: 700;
        font-size: 1.3rem;
    }

    &__opening-hours {
        font-size: 0.65rem;
    }

    &--hidden {
        display: none;
        opacity: 0;
        transition: opacity 0.3s ease;
    }
}