#CybotCookiebotDialogDetail {
  thead, tbody, tfoot{
    border: none;
    background: none;
    background-color: none;
  }

  tbody tr:nth-child(even) {
    border: none;
    background: none;
    background-color: none;
  }
}