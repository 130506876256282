.homepage-search-container {
  background-color: $very-light-gray;
  padding: 20px 0;
  margin-bottom: 2rem;
}

.homepage-search {
  position: relative;
}

.homepage-search--header {
  //background-color: white;
  display: flex;
  flex-direction: column;
  width: 100%;
  //padding: 0 15px;
  align-items: center;

  @media #{$large-up} {
    flex-direction: row;
    height: 70px;
  }
}

.homepage-search--heading {
  text-align: center;
  font-size: 26px;
  line-height: 70px;
  margin: 0;
  padding: 0;

  @media #{$medium-up} {
    font-size: 36px;
    text-align: left;
  }
}

.homepage-search--added-value {
  color: $gold;
  font-weight: bold;
  text-align: center;
  display: none;
  margin-bottom: 1.5rem;

  @media #{$medium-up} {
    display: block;
  }

  @media #{$large-up} {
    padding-right: 2rem;
    text-align: left;
  }
}

.homepage-search--reset {
  position: absolute;
  top: 10px;
  right: 30px;
  text-decoration: underline;
  cursor: pointer;
  color: $offer-red;
  line-height: 50px;
  white-space: nowrap;
  font-size: 15px;
  font-weight: 400;

  @media #{$large-up} {
    top: auto;
    right: auto;
    position: relative;
    line-height: 70px;
    text-decoration: none;
    margin: 0 0 0 auto;
  }
  .icn {
    margin-left: 5px;
  }

  &:hover {
    color: darken($dark-red, 20%);
  }

}

.homepage-search--advanced {
  z-index: 0;
  margin: 0;
  padding: 0 15px;
  position: relative;
  white-space: nowrap;
  margin-bottom: 20px;

  @media #{$medium-up} {
    margin-left: 15px;
    line-height: 70px;
    margin-bottom: 0;
    padding-right: 0;

    &:before {
      content: ' ';
      width: 1px;
      height: 20px;
      background-color: black;
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -10px;
    }
  }
}

.homepage-search--mobile-advanced {
  z-index: 0;
  margin: 0;
  padding: 20px 15px;
  position: relative;
  width: 100%;
  text-align: center;
  background-color: white;

  @media #{$large-up} {
    display: none;
  }
}

.homepage-search--panel {
  position: relative;
  display: none;

  @media #{$large-up} {
    display: block;
  }

  &.open {
    @media screen and (max-width: 1023px ) {
      @include stacking-context(modal-parent);
      background-color: $very-light-gray;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      padding-top: 50px;
      padding-bottom: 50px;
    }

  }

  .ui-modal {
    @media #{$large-up} {
      left: 10px;
    }
  }
}

.homepage-search--row {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 0 15px;
  margin: 0 -10px;

  @media #{$large-up} {
    padding: 0;
    flex-direction: row;
    flex-wrap: nowrap;

    .search-field {
      width: 100%;
      flex: 1;

      & + & {
        margin-bottom: 0;
        margin-left: 20px;
      }

      &--slider {
        flex: 0 0 50%;
      }
    }
  }
}

.search-field--submit {
  height: 0;
  margin: 0;

  i {
    width: 14px;
    height: 15px;
  }

  .search-field--label {
    display: none;
  }

  @media #{$medium-up} {
    height: 80px;
    margin-bottom: 20px;

    .search-field--label {
      display: block;
    }
  }
}

.homepage-search--facet {
  position: relative;
}

.homepage-search--button {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  height: auto;
  line-height: 1;
  padding: 0.85em 1em;

  @media #{$medium-up} {
    height: 50px;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 43px;
    position: relative;
  }
}

.homepage-search--simple-bar {
  max-height: calc(100% - 71px);

  @media #{$large-up} {
    max-height: none;
  }
}

.homepage-search--modal-header {
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #243753;
  color: white;
  height: 50px;

  @media #{$large-up} {
    display: none;
  }
}

.homepage-search--modal-title {
  z-index: 0;
  font-size: 18px;
  font-weight: bold;
  line-height: 50px;
  margin-right: 50px;
  margin-left: 50px;
  text-align: center;
}

.homepage-search--modal-close {
  z-index: 0;
  position: absolute;
  top: 0;
  right: 0;
  height: 50px;
  width: 50px;
  background-color: rgb(69, 85, 109);
  line-height: 50px;
  color: white;
  text-align: center;
  vertical-align: center;
  cursor: pointer;

  @media #{$large-up} {
    display: none;
  }

  > span {
    display: none;
    @media #{$large-up} {
      display: inline-block;
      text-decoration: underline;
    }
  }

  &:before, &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: 0;
    margin-left: -10px;
    width: 20px;
    height: 2px;
    display: block;
    background-color: white;

    transform: rotate(45deg);
    transform-origin: center center;

    @media #{$large-up} {
      left: 100%;
      background-color: $dark-red;
      width: 12px;
      height: 2px;
    }
  }

  &:after {
    transform: rotate(-45deg);
  }
}

.homepage-search--modal-heading {
  z-index: 0;
  padding-left: 15px;
  line-height: 50px;
  margin-top: 10px;
  font-weight: 900;
  font-size: 16px;
  font-style: normal;
  font-family: $body-font-family;

  @media #{$large-up} {
    display: none;
  }
}