.panel {
  border: 1px solid $light-gray;
  padding: 15px;
}

.panel--header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}