//TODO REFACTOR HTML ON BLOG DRIVER SO THIS CAN BE REMOVED
@mixin image-overlay-tab() {

    @include rotate(45);
    position: absolute;
    display: block;
    width: 150px;
    height: 150px;
    left: -75px;
    top: -75px;
    &.popular{
        background: rgba($pinterest, 0.5);
    }
    &.selling-fast{
        background: rgba($facebook, 0.5);
    }
    p{
        @include rotate(-45);
        font-size: 0.78rem;
        color: $white;
        width: rem-calc(60);
        top: rem-calc(55);
        left: rem-calc(85);
        padding-left: rem-calc(10);
        position: absolute;
    }

}

//================================================//
//              PLEASE REFACTOR ME!!!             //
// ----------------------------------------       //
// Copied from _drivers.scss.old                  //
// Rename .promo-driver to .random-driver         //
//================================================//
//Image Info Driver & Promo Driver Start
.image-info-driver,
.random-driver{
    .driver-inner-container{
        position: relative;
    }

    p{
        color: $light-navy;
    }
    a.text-link{
        color: $gold;
    }
    .driver-info {
        padding: 2rem 1rem;
        display: flex;
        flex-direction: column;

        @include breakpoint (small only) {
            padding: 2rem 0;
        }
        &.pull-up {
            margin-top: 0;
            @include breakpoint (large) {
                margin-top: rem-calc(-30);
            }
        }
        img.cruise-line-logo{
            margin-bottom: 1.5rem;
        }
        h3{
            font-size: rem-calc(26);

            a {
                color: $navy;
            }
        }
        hr{
            border-color: $navy;
            width: 20%;
        }
        p{
            color: $light-navy;
        }
    }
    p.date{
        font-family: $header-font-family;
        font-style: italic;
        margin-bottom: 0.5rem;
    }

    .promo-link{
        margin-bottom: 1rem;
        img{
            @include rounded-corners(50%);
        }
        p{
            margin-bottom: 0;
        }
        p.date{
            margin-top: rem-calc(5)
        }
    }
    .mini-coupon{
        .mini-coupon-inner-wrapper {
            &.jobs{
                margin-bottom: 1rem;
            }
            position: relative;
            img {
                @include rounded-corners(50%);
            }
        }
    }
    .mini-coupon:first-of-type{
        margin-top: 1rem;
    }
    // Don't hate....
    @media#{$medium-only}{
        &.medium-centered {
            float: none !important;
        }
    }
}
//Image Info Driver & Promo Driver End


//Overlay Driver Start
.overlay-driver{
    .driver-inner-container{
        position: relative;
        .driver-info{
            padding: 4rem 1rem 0 1rem;
            position: absolute;
            bottom: 0;
            img{
                width: rem-calc(36);
                margin-bottom: 0.25rem;
            }
            p,
            h2,
            h3,
            h4,
            h5{
                color: $white;
            }
            h3{
                font-size: rem-calc(46);
            }
            p{
                font-size: rem-calc(18);
            }
            hr{
                width: 40%;
                margin-top: 1rem;
                margin-bottom: 1rem;
            }

        }
    }
}
//Overlay Driver End


.results-grid-layout{
    display: -webkit-flex;
    -webkit-flex-wrap: wrap;

    display: flex;
    flex-wrap: wrap;

}

//Product Driver Start
ul.equal-height{
    margin-left: 0;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    display: flex;
    display: -webkit-flex;
    li{
        list-style: none;
        &.product-driver{
            display: -webkit-flex;
            display: flex;
            .driver-inner-container{
                -webkit-flex-direction: column;
                display: -webkit-flex;
                display: flex;
                flex-direction: column;
                width: 100%;
                .summary-container{
                    -webkit-flex: 1 0 auto;
                    flex: 1 0 auto;
                    &.pull-up{
                        margin-top: -3rem;
                    }
                }
            }
        }
    }
}

.product-driver {
    .driver-inner-container {
        .summary-container {
            h4{
                a {
                    font-family: $body-font-family;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 300;
                    color: $gold;
                }
            }
            p{
                color: $navy;
                font-size: 16px;
                font-style: normal;
                font-weight: 300;
            }
            hr{
                border-bottom: rem-calc(1) solid $navy;
                margin: 1rem auto;
                width: 50%;
            }
        }
    }
}


.product-driver{
    .driver-inner-container{
        .image-container{
            overflow: hidden;
            position: relative;
            display: block;
            .image-overlay-tab{
                @include image-overlay-tab();
            }

        }
        .price-container{
            a {
                font-family: $body-font-family;
                font-style: normal;
                font-weight: normal;
                @media#{$small-only}{
                    font-size: 16px;
                }
            }
            span.divider{
                color: $gold;
                margin: 0 0.5rem;
                font-size: 18px;
            }
            a.text-link{
                font-size: 16px;
                &.underline{
                    text-decoration: underline;
                }
            }
            // price description on list view
            .price-description{

                color: $gold;
                text-align: center;
                p {
                    font-size: 1rem;
                    color: $gold;
                    padding: 0;
                    margin: 0;
                    font-weight: 800;
                    text-align: center;
                }
            }
            sub {
                bottom: 0;
            }
        }
        img:first-child {
            width: 100%;
        }
        img:last-child {
            width: initial;
            width: auto;
        }
    }
}

.product-driver {
    .driver-inner-container {
        .product-info {
            p{
                color: $navy;
                font-size: 120%;
                font-style: normal;
                font-weight: 300;
            }
            span.divider{
                color: $gold;
                margin: 0 0.5rem;
                font-size: 18px;
            }
            a.text-link{
                font-size: 16px;
                &.underline{
                    text-decoration: underline;
                }
            }
        }
    }
}

@media only screen and (min-width: 640px) and (max-width: 700px) {
    .product-driver {
        .driver-inner-container {
            .product-info {
                span.divider{
                    margin: 0;
                }
                a.text-link{
                    font-size: 16px;
                }
            }
        }
    }
}
//Product Driver End

//Product Drive Landscape Start
.product-driver-landscape{
    
       @media#{$small-only}{
                text-align: center;
            }
    h4{
        font-family: $body-font-family;
        font-weight: 300;
        font-style: normal;
        margin: 1rem 0;
        color: $gold;
    }
    hr{
        margin-bottom: rem-calc(5);
        width: 30%;
    }
    .cruise-logo{
        img{
            margin: 1rem 0;
        }
    }
    p{
        color: $navy;
    }
    p.date-info{
        font-size: 110%;
    }
    span.divider{
        color: $gold;
        margin: 0 0.5rem;
        font-size: 18px;
    }
    a.text-link{
        font-size: 16px;
        &.underline{
            text-decoration: underline;
        }
    }
}

.enquiry.cruise-driver{
    div.now{
        font-weight: 300;
        color: $gold;
        //text-align: center;
        p {
            font-weight: 800;
            color: $gold;
            //text-align: left;
        }
    }
}

.product-driver-landscape {
    .price-box {
        width: 100%;
        float: left;
        padding: 1rem;
        border: rem-calc(1) solid $gold;
        hr{
            border: rem-calc(1) solid $gold;
            margin: 1rem auto;
            width: 60%;
        }
        .text-link{
            font-size: 16px;
            width: 100%;
            float: left;
            clear:left;
            text-align: center;
            margin-bottom: 0.5rem;
            text-decoration: underline;
        }
        p.was,
        p.now{
            font-weight: 300;
            color: $gold;
            text-align: center;
            sub,span {
                font-size: 1rem;
                bottom: 0;
            }
            &.precede-description {
                margin-bottom:0;
            }
        }
        // current price and description style on grid view
        div.now{
            font-weight: 300;
            color: $gold;
            text-align: center;
            p {
                font-weight: 800;
                color: $gold;
                text-align: center;
            }
        }
        .was{
            margin-bottom: 0;
            span{
                font-size: 18px;
            }
        }
        .now{
            margin-bottom: 0;
            @media#{$large-up}{
                margin-bottom: 1rem;
            }
            span{
                font-size: 18px;
            }
        }
    }
    .button{
        text-transform: uppercase;
        width: 100%;
    }
}

li.product-driver.grid {
    .image-container {
        position: relative;
        overflow: hidden;
        .image-overlay-tab {
            @include image-overlay-tab();
        }
    }
}

.product-driver-landscape,
.enquiry.cruise-driver{
    .image-container {
        position: relative;
        overflow: hidden;

        .image-overlay-tab {
            @include image-overlay-tab();
        }

    }
}

.product-driver-landscape {
    &.cabin-driver {
        
        .cabin-image-wrapper {
            vertical-align: middle;
            // background: $white;
            border: 1px solid $gold;
            
            img {
                //  clip-path: inset(0px 50px 0px 50px);
                //  -webkit-clip-path: inset(0px 50px 0px 50px);
                //  -moz-clip-path: inset(0px 50px 0px 50px);
                padding: 2rem;
                vertical-align: middle;
            }
            
        }
        
        hr {
            width: 10%;
        }
        
        
        .button {
            text-transform: initial;
        }
        
        h3 {
              @media#{$small-only}{
                  padding-top: 1rem;
              }
        }
        p {
            padding-top: 1rem;
        }
        
        .cabin-cta-wrapper {
            padding-top: 1rem;
            @media#{$small-only}{
                  padding-top: 0;
              }
        }
        @media#{$medium-up}{
            table {
                th, td {
                    &.deck-letter {
                        width: 10%;
                    }
                    &.deck {
                        width: 50%;
                    }
                    &.price,
                    &.deck-plan {
                        width: 20%;
                    }
                }
            }
        }


        @media#{$small-only}{
            table {
                th, td {
                    font-size: 13px;
                    padding: 0.3rem;
                }
            }
        }


        
    }
}
//Product Driver Landscape End


//Product Driver Landscape Account Start
.product-driver-landscape {
    &.account{
        ul{
            float: left;
            width: 100%;
            margin-left: 0;
           li{
               list-style: none;
               position: relative;
               float: left;
               width: 100%;
               margin-bottom: rem-calc(5);
               img{
                   position: absolute;
                   top: rem-calc(6);
                   left: 0;
               }
               p{
                   padding-left: rem-calc(40);
                   margin-bottom: 0;
                   width: 100%;
                   float: left;
               }
               a.text-link{
                   padding-left: rem-calc(40);
                   font-size: 16px;
                   clear: left;
               }
           }
        }
    }
}

.product-driver-landscape {
    &.account {
        .price-box{
            border-bottom: none;
            &.no-payment {

                border-bottom: rem-calc(1) solid $gold;
            }
            &.account{
                p{
                    color: $gold;
                    font-size: 120%;
                    margin-bottom: rem-calc(8);
                    span{
                        color: $navy;
                        font-size: 80%;
                    }
                }
                p:last-of-type{
                    margin-bottom: 0;
                }
            }
        }
        .button{
            &.white{
                margin-bottom: 0;
            }
        }
    }
}
//Product Driver Landscape Account End


//Product Driver Grid View Start
.grid-view {
    .product-driver-landscape {
        text-align: center;
        .small-pull-up{
            margin-top: rem-calc(-50);
            position: relative;
            z-index: 5;
        }
    }
}
//Product Driver Grid View End

