.input-button {
  line-height: 39px;
  padding: 0;
  background-color: $gold;
  color: white;
  text-align: center;
  border: 0;
  display: inline-block;
  cursor: pointer;
  white-space: nowrap;

  @media #{$large-up} {
    padding: 0 1rem;
  }

  &.transparent {
    background-color: transparent;
    color: $gold;
  }

  &.expanded {
    width: 100%;
  }
}

