.cruise-checkbox-wrapper {
  .checkbox {
    margin: 1rem;
    display: block;
    width: 100%;
    text-align: center;
    cursor: pointer;

    span {
      position: relative;
      line-height: 30px;
      padding-left: 40px;
      display: inline-block;
      vertical-align: middle;
      transition: all 500ms;
    }

    span:hover {
      color: $gold;
      &:before {
        border: 1px solid $gold;
        color: rgba($light-gray, 0.5);
      }
    }
    span:before {
      transition: all 500ms;
      content: '✔';
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -15px;
      margin-right: 10px;
      display: inline-block;
      border: 1px solid $dark-gray;
      background-color: white;
      height: 30px;
      width: 30px;
      font-size: 1.7rem;
      color: rgba($light-gray, 0);
    }

    input {
      display: none;
    }

    input:checked ~ span:before {
      content: '✔';
      color: $black;
    }


  }

  &.active .cruise-checkbox{
    border: 2px solid $rhino;
    background-color: $rhino;
  }
}

.cruise-checkbox {
  transition: all 500ms;
  padding: 2rem 1rem;
  border: 2px solid $medium-gray;
}