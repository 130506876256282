.coupon-elements-section {
  /*  &.homepage--top-panel{
        .image-with-text {
            text-align: center;
            &.large{
                .info {
                    padding: 8% 6.66667rem;
                    background: none;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
            .inner-container {
                display: block;

                &:before{
                    content: " ";
                    z-index: 10;
                    display: block;
                    position: absolute;
                    height: 100%;
                    top: 0;
                    left: 0;
                    right: 0;
                    background: rgba(0, 0, 0, 0.25);
                }

                &:hover {
                    &:before {
                        background: rgba(0, 0, 0, 0.4);
                    }


                    .info {
                        color: $gold;
                        * {
                            color: $gold;
                        }
                    }

                    .button {
                        color: white;
                        background-color: $dark-blue;
                        border-color: $dark-blue;
                    }
                }


            }
            .info {
                z-index: 10;
                h3 {
                    margin-bottom: 1rem;

                    @media screen and (min-width: 1024px) and (max-width: 1169px) {
                        font-size: 1.6rem;
                    }

                    @media screen and (min-width: 640px) and (max-width: 815px) {
                        font-size: 1.6rem;
                    }

                }
                hr {
                    width: 20%;
                    margin: 0 auto;
                    margin-bottom: 1rem;
                }
                p{
                    margin-bottom: 1rem;
                    font-size: 120%
                }
                a {
                    color:$white;
                }
            }
        }
    }*/

    &.faq {
        margin-bottom: rem-calc(20);
        @include breakpoint(small only) {
            margin-bottom: rem-calc(0);
        }
    }
    a.coupon-inner-container {
        display: inline-block;
    }
    .coupon-inner-container {
        position: relative;
        margin-bottom: rem-calc(20);
        width: 100%;
        i {
            width: auto;
            left: 50%;
            margin-left: rem-calc(-28);
            @include vertical-align($position: absolute);
            color: $white;
            font-size: 450%;
            z-index: 10;
            @media#{$small-only}{
                margin-left: rem-calc(-18);
                font-size: 250%;
            }
        }
        .coupon-info {
            @include linear-gradient($transparent, rgba($black, 0.65));
            padding: 4rem 1rem 1rem 1rem;
            position: absolute;
            bottom: 0;
            width: 100%;
            @media#{$small-only}{
                max-height: 235px;
            }
            img {
                margin-bottom: 0.25rem;
            }
            p,
            h2,
            h3,
            h4 {
                color: $white;
                a {
                    color: $white;
                }
            }
            p {
                line-height: rem-calc(18);
                margin-bottom: 0;
                font-size:1rem;
                &.date {
                    font-family: $header-font-family;
                    font-style: italic;
                    color: $white;
                }
            }
            hr {
                width: 20%;
                margin-top: 0.25rem;
                margin-bottom: 0.5rem;
                border-color: $white;
            }



        }
        .no-copy {
            hr {
                margin-bottom: 1.33333rem;
            }
        }

        > img {
            width: 100%;
        }
    }
}

.coupon-elements-section {
    .faq-coupon-inner-container {
        border: 1px solid $medium-gray;
        padding: rem-calc(0 30 30 30);
        z-index: -10;
        margin-bottom: rem-calc(30);

        display: block;
        width: 100%;

        &:hover{
            background: darken($white, 7%);
        }

        .fa {
            color: $gold;
        }

        .faq-coupon-inner-container-icon {
            margin: auto;
            text-align: center;
            z-index: 10;
            bottom: rem-calc(24);
            margin-top: rem-calc(-20);
            margin-bottom: 2rem;
        }
        p {
            color: $navy;
            &.faq-title {
                text-transform: uppercase;

            }
        }
        @include breakpoint(small only) {
            margin-bottom: rem-calc(60);
        }

    }
}

.coupon-elements-section {
    .mini-coupon {
        .coupon-inner-container {
            position: relative;
            overflow: hidden;

            &:before {
                height: 0;
                padding-top: 100%;
                width: 100%;
                overflow: hidden;
                content: ' ';
            }

            a {
                display: block;
                width: 100%;
                height: 100%;
            }

            img{
                width: 100%;
            }
            .coupon-info {
                padding: 1rem 0.5rem 0.5rem 0.5rem;
                h3 {
                    font-size: 130%;
                }
            }

            &:hover {
                .coupon-info h3 {
                    color: $gold;
                }

            }
        }
    }
    p.arrow-right {
        i {
            position: relative;
            left: 0;
            top: 0;
            font-size: 120%;
            margin-left: 0;
            margin-top: 1rem;
            -webkit-transform: translateY(0%);
            -ms-transform: translateY(0%);
            transform: translateY(0%);
            z-index: 0;
        }
    }

    &.withLogos {
        .coupon-inner-container {
            margin-bottom: 0.5rem;

        }
    }
    &.noCouponText {
        .coupon-inner-container {
            @media#{$small-only}{
                max-height: 180px;
                overflow: hidden;

                .coupon-info {
                    //background: -moz-linear-gradient(top, transparent 0%, rgba(10, 10, 10, 0.65) 126%);
                    //background: -webkit-linear-gradient(top, transparent 0%, rgba(10, 10, 10, 0.65) 126%);
                    background: linear-gradient(to bottom, transparent 0%, rgba(10, 10, 10, 1) 126%);
                    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='transparent', endColorstr='rgba(10, 10, 10, 1)', GradientType=0);
                }
            }
        }
        // decrease the height on mobile devices to save space
    }
}

.coupon-elements-section .margin-bottom {
    margin-bottom: 1.66%;
}

.coupon {
        min-height: 436px;
}


// PROMO COUPONS
.promo-coupons {
    h3 {
        @media #{$medium-only}{
            font-size: 1.7rem;
        }
    }
}
@media #{$medium-only} and (orientation : portrait) {
    .hide-for-medium-portrait-only {
        display: none;
    }
}

.promo-summary-text{
    display: block;

    @media only screen and (min-width: 640px) and (max-width: 800px)  {
        display: none;
    }

    @media only screen and (min-width: 640px) and (max-width: 800px) and (orientation: portrait)  {
        display: block;
    }

}


@media only screen and (max-width:450px){
    .coupon-elements-section.homepage--top-panel {
        .image-with-text.large {
            .info {
                padding: 0 15px;
            }
        }
    }
}




