.slider{
    &.partners{
        height: auto;
        margin-top: 1rem;
        margin-bottom: 1rem;
        background: none;
        img{
            display: inline-block;
            width: auto;
            max-height: 120px;
        }
    }

    .partners-slider-buttons {
        bottom: auto;
        left: auto;
        width: 100%;

        button {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 3rem;
            height: 3rem;
            outline: none;

            &.left {
                left: -50px;
            }

            &.right {
                right: -50px;
            }

            .icn {
                font-size: 2rem;
                height: 0;
                width: 0;
                color: $light-charcoal;

                @media #{$medium-up} {
                    font-size: 4rem;
                }
            }
        }
    }
}