.feefo-driver {
  @include stacking-context(default);
  //margin: 15px;
  margin: 30px 15px;
  padding: 30px 30px 65px;
  position: relative;
  text-align: left;
  height: 640px;

  @media only screen and (min-width: 417px) {
    height: 460px;
  }

  @media only screen and (min-width: 540px) {
    height: 370px;
  }

  @media #{$medium-up} {
    height: 520px;
  }

  @media only screen and (min-width: 870px) {
    height: 410px;
  }


  @media #{$large-up} {
    height: 340px;
  }

  @media #{$xlarge-up} {
    height: 300px;
  }
}

.feefo-driver--title {
  font-size: 20px;
  font-family: $body-font-family;
  color: black;
  font-weight: 800;
  text-decoration: none;
  font-style: normal;
  line-height: 30px;
  margin-bottom: 15px;
}

.feefo-driver--text {
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.3px;
  line-height: 28px;
  margin-bottom: 15px;
}

.feefo-driver--rating {
  background-color: #ffcf03;
  height: 30px;
  position: absolute;
  right: 0;
  bottom: 30px;
  line-height: 30px;
  color: black;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 0 30px 0 15px;
  margin: 0;
}

.feefo-driver--date {
  color: $charcoal;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
  text-transform: uppercase;

  @media #{$medium-up} {
    height: 30px;
    line-height: 30px;
    position: absolute;
    left: 0;
    bottom: 30px;
    padding: 0 30px;
  }
}


.feefo-driver--rating i {
  color: inherit;
}