$menu-bg: white;
$button-bg: white;
$border: #cacaca;
$active-border: $gold;
$item-height: 50px;

$transition-time: 0.15s;
$transition-type: ease-out;

.sort {
  @include stacking-context(expandable);
  position: relative;
  display: inline-block;
  cursor: pointer;

  &:focus {
    outline: none;

    .sort--options {
      outline: #cf9d63 solid 1px;
      outline-offset: 1px;
    }
  }
}

.sort.active {
  .sort--selected {
    border-color: $active-border;
  }
  .sort--options {
    border-color: $active-border;
    max-height: none;
    padding-bottom: 10px;
    box-shadow: 0 10px 15px 5px rgba(0, 0, 0, 0.2);
  }
}

.sort--selected {
  z-index: 1;
  margin: 0;
  position: relative;
  height: $item-height - 1;
  line-height: $item-height - 2;
  border: 1px solid $border;
  background-color: $button-bg;
  padding: 0 15px;
  cursor: pointer;
  display: block;
  min-width: 225px;
  font-size: 14px;
  font-weight: 600;
  color: $dark-slate;
  border-bottom: none;

  i {
    position: absolute;
    right: 8px;
    top: 50%;
    margin-top: -6px;
  }
}

.sort--options {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background-color: $menu-bg;
  color: $dark-slate;
  padding: ($item-height - 2) 0 0 0;
  overflow: hidden;
  max-height: $item-height;
  transition: all $transition-time $transition-type;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  border: 1px solid $border;
}

.sort--label {
  display: block;
  width: 100%;
  line-height: $item-height;
  text-align: left;
  color: $dark-slate;
  font-size: 14px;
  font-weight: 400;
  padding: 0 15px;

  &:hover {
    font-weight: 600;
    background-color: $very-light-gray;
  }
}


