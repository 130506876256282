.cruise-driver {
    background-color: white;
    display: block;
    position: relative;
    height: auto;
    overflow: hidden;
    margin-bottom: 2rem;
    white-space: normal;

    -webkit-box-shadow: 0 0 15px rgba(204,204,204,1);
    -moz-box-shadow: 0 0 15px rgba(204,204,204,1);
    box-shadow: 0 0 15px rgba(204,204,204,1);
}


.cruise-driver--top-wrapper {
    position: relative;
    background-color: #042a2f;
}

.cruise-driver--banner {
    background-color: currentColor;
    position: absolute;
    top: 0;
    left: 0;
    color: $rhino;
    width: auto;
    padding: 0.5rem 1rem;
    font-size: 15px;
    line-height: 2rem;
    height: 3rem;
    z-index: 8;
    text-transform: uppercase;

    &:after {
        content: ' ';
        position: absolute;
        right: -2rem;
        top: 0;
        border-bottom: 1.4rem solid transparent;
        border-left: 1rem solid currentColor;
        border-top: 1.6rem solid currentColor;
        border-right: 1rem solid transparent;
    }
}

.cruise-driver--banner-text {
    color: white;
}

.cruise-driver--image {
    height: 200px;
    width: 100%;
    display: block;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    position: relative;

    &:before {
        content: ' ';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: black;
        opacity: 0;
        z-index: 5;
        transition: all 500ms;
    }

    &:hover:before {
        opacity: 0.3;
    }

    picture {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    picture img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.cruise-driver--favorites {
    position: absolute;
    top: 0;
    right: 0;
    height: 45px;
    width: 45px;
    color: white;
    z-index: 5;
    line-height: 45px;
    font-size: 18px;
    text-align: center;
    cursor: pointer;

    i {
        text-shadow: 0px 0px 2px rgba(0,0,0,0.5);
    }
}

.cruise-driver--bottom-wrapper {
    padding: 15px 25px 0 25px;
    padding-bottom: 120px;
    position: relative;
}

.cruise-driver--logo-wrapper {
    height: 50px;
    line-height: 50px;
    vertical-align: middle;
    display: block;
    position: absolute;
    bottom: 0;
    left: 20px;
    background-color: white;
    padding: 10px;

    img {
        vertical-align: top;
        line-height: 30px;
        max-height: 30px;
        max-width: 100%;
        height: auto;
        width: auto;
        display: inline-block;
    }
}

a.cruise-driver--heading {
    color: $dark-blue !important;
    font-size: 22px;
    line-height: 28px;
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: $body-font-family;
    font-weight: 700;

    &:hover {
        color: $gold;
    }
}

.cruise-driver--info {
    margin: 0;
    text-align: left;
    font-size: 13px;
    margin-bottom: 20px;
    letter-spacing: 0.3px;

    span {
        margin: 0px 10px 8px 0;
        border-right: 1px solid #8a8a8a;
        padding-right: 12px;
        height: 12px;
        line-height: 14px;
        vertical-align: middle;
        display: inline-block;
    }

    span:last-of-type {
        border: none;
    }

    i{
        margin-right: 3px;
        width: 18px;
        display: inline-block;
        color: $gold;
    }

    .icn-tick {
        top: -3px;
    }
}

.cruise-driver--included {
    font-size: 14px;
    line-height: 15px;
    text-align: left;
    margin: 20px 0;

    p {
        margin-bottom: 5px;
        overflow: hidden;
        white-space: nowrap;
        text-wrap: none;
        text-overflow: ellipsis;
    }

    i {
        top: -3px;
        margin-right: 5px;
    }
}

.cruise-driver--itinerary {
    margin-bottom: 1rem;
    line-height: 1.2rem;
    font-size: 0.9rem;
    color: $charcoal;
    text-align: left;
    text-decoration: underline;
}

.cruise-driver--itinerary-large {
    display: none;
    font-size: 0.9rem;
}

.cruise-driver--price-wrapper {
    font-size: 12px;
    line-height: 18px;
    display: block;
    text-align: right;
    position: absolute;
    bottom: 60px;
    right: 20px;
    flex-direction: column;
    height: 60px;
    padding-bottom: 20px;
    padding-top: 20px;
    margin-bottom: 0;

    .was {
        position: absolute;
        top: 0;
        right: 0;
        height: 16px;
    }

    .price{
        display: block;
        font-size: 18px;
        font-family: $body-font-family;

        strong{
            font-size: 25px;
        }
    }

    .offer{
        position: absolute;
        bottom: 0;
        right: 0;
        height: 16px;
        font-size: 12px;
        font-style: italic;
    }
}

.cruise-driver--button.button {
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 0;
    width: 100%;
}

//Responsive styling
@mixin cruise-driver_basic() {

    .cruise-driver--icons {
        width: 100%;
    }

    .cruise-driver--button {
        width: 100%;
    }
}

@mixin cruise-driver_full() {
    display: flex;
    flex-direction: row;

    .cruise-driver--logo-wrapper {
        bottom: 70px;
        left: calc(100% + 20px);
        width: 50%;
    }

    .cruise-driver--offset-content-wrapper {
        top: 0;
    }

    .cruise-driver--price-wrapper {
        padding-bottom: 0;
        flex-grow: 0;
    }

    .cruise-driver--content-wrapper {
        padding: 0;
        height: 100%;
    }

    .cruise-driver--offset-content-wrapper {
        padding-left: 1rem;
        height: 100%;
        padding-right: 1rem;
        //padding-bottom: 3.6rem;
    }

    .cruise-driver--top-wrapper {
        width: 370px;
        height: auto;
        display: flex;

        .cruise-driver--image{
            min-height: 282px;
            height: auto;
            flex-grow: 1;
        }
    }

    .cruise-driver--bottom-wrapper {
        width: calc(100% - 370px);
        float: left;
        display: block;
        height: auto;
    }

    .cruise-driver--itinerary-large {
        display: block;
    }

    .cruise-driver--itinerary {
        display: none;
    }
}

.cruise-driver--equalise-container{
    display: flex;
    flex-wrap: wrap;

    .column{
        margin-bottom: 2rem;
        @include breakpoint(medium only) {
            &.medium-6 {
                width: calc(calc(100% / 2) - 1px);
            }
        }
        @include breakpoint(large up) {
            &.large-4 {
                width: calc(calc(100% / 3) - 1px);
            }
        }
    }

    .cruise-driver{
        display: flex;
        flex-direction: column;
        height: 100%;

        .cruise-driver--bottom-wrapper{
            flex-grow: 1;

            .cruise-driver--button{
                bottom: 0;
                position: absolute;
                left: 0;
                width: 100%;
            }
        }
    }
}

.cruise-driver.basic {
    @include cruise-driver_basic()
}

.cruise-driver.full {
    @media #{$large-up} {
        @include cruise-driver_full()
    }
}

.cruise-driver--carousel {
    &.slick-slider:not(.cruise-checkbox--carousel) {
        margin-bottom: 70px;
    }

    &.cruise-checkbox--carousel {
        & .slick-list {
            flex: 1;
        }
    }

    .cruise-driver {
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .slick-dots{
        margin-left: 0;
    }

    .slick-slide:focus {
        outline: none !important;
    }

    .slick-prev {
        left: -35px;
        margin-top: -60px;
        &:before {
            content: ' ';
            background-image: url('/images/slider-arrow-prev.png');
            background-size: 100%;
            display: block;
            height: 40px;
            width: 20px;
            background-repeat: no-repeat;
            background-position: 50% 50%;
        }
    }

    .slick-next {
        right: -35px;
        margin-top: -60px;
        &:before {
            content: ' ';
            background-image: url('/images/slider-arrow-next.png');
            background-size: 100%;
            display: block;
            height: 40px;
            width: 20px;
            background-repeat: no-repeat;
            background-position: 50% 50%;
        }
    }

    // This is horrid, I know.
    .cruise-driver.wide{
        position: relative;

        .cruise-driver--bottom-wrapper{
            position: initial;
            padding-bottom: 93px;

            .cruise-driver--button{
                position: absolute;
                left: 0;
                width: 100%;
                bottom: 0;
            }
        }
    }

}


