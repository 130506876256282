@font-face {
  font-family: 'fontawesome';
  src:  url('/fonts/icomoon.eot');
  src:  url('/fonts/icomoon.eot?9ab2pb#iefix') format('embedded-opentype'),
  url('/fonts/icomoon.ttf') format('truetype'),
  url('/fonts/icomoon.woff') format('woff'),
  url('/fonts/icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.fa {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'fontawesome' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fa-linkedin-in:before {
  content: "\f0e1";
}
.fa-youtube:before {
  content: "\f167";
}
.fa-user:before {
  content: "\f008";
}
.fa-th:before {
  content: "\f00a";
}
.fa-th-list:before {
  content: "\f00b";
}
.fa-list:before {
  content: "\f03a";
}
.fa-chevron-left:before {
  content: "\f053";
}
.fa-chevron-right:before {
  content: "\f054";
}
.fa-info-circle:before {
  content: "\f05a";
}
.fa-eye:before {
  content: "\f06e";
}

.fa-eye-slash:before {
  content: '\f070';
}

.fa-plane:before {
  content: "\f072";
}
.fa-calendar-alt:before {
  content: "\f073";
}
.fa-chevron-up:before, .icon-angle-up:before   {
  content: "\f077"; // content: "\f106";
}
.fa-chevron-down:before, .icon-angle-down:before {
  content: "\f078"; // content: "\f107";
}

.fa-phone:before {
  content: "\f095";
}
.fa-moon:before {
  content: "\f186";
}
.fa-plane-arrival:before {
  content: "\f5af";
}
.fa-plane-departure:before {
  content: "\f5b0";
}
.fa-calendar-alt1:before {
  content: "\f074";
}
.fa-search:before {
  content: "\f002";
}
.fa-star:before {
  content: "\f005";
}
.fa-twitter:before {
  content: "\f099";
}
.fa-facebook:before {
  content: "\f09a";
}
.fa-facebook-f:before {
  content: "\f09a";
}
.fa-star-half-empty:before {
  content: "\f123";
}
.fa-star-half-full:before {
  content: "\f123";
}
.fa-star-half-o:before {
  content: "\f123";
}
.fa-long-arrow-left:before {
  content: "\f177";
}
.fa-long-arrow-right:before {
  content: "\f178";
}
.fa-ship:before {
  content: "\f21a";
}
.fa-anchor:before {
  content: "\f13d";
}
.fa-globe:before {
  content: "\f0ac";
}

.fa-times:before {
   content: "\f00d";
 }

.fa-heart:before {
  content: "\f004";
}

.fa-heart-o:before {
  content: "\f08a";
}

.fa-caret-down:before {
  content: "\f0d7";
}

.fa-plus:before {
  content: "\f067";
}
.fa-instagram:before {
  content: "\f16d";
}

.fa-clock:before {
  content: "\f017";
}

.fa-exchange:before {
  content: "\f0ec";
}

.fa-pin:before {
  content: "\e900";
}

.fa-location-arrow:before {
  content: "\f124";
}
.fa-x:before {
  content: "\e901";
}
.fa-checkmark:before {
  content: "\ea10";
}