.pagination {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  padding: 0 25px;
}

.pagination--page {
  height: 32px;
  width: 32px;
  line-height: 30px;
  text-align: center;
  background-color: white;
  border: 1px solid $light-gray;
  color: black;
  font-size: 12px;
  border-radius: 50%;
  position: relative;
  display: inline-block;
  cursor: pointer;

  & + & {
    margin-left: 7px;

    @media #{$large-up} {
      margin-left: 20px;
    }
  }

  &.active {
    background-color: $gold;
    border: 1px solid $gold;
    color: white;
  }

  &:hover {
    background-color: rgba(207, 157, 99, 0.5);
    border: 1px solid transparent;
  }

  &.prev, &.next {
    span { display: none }
    border-color: transparent;
    background-color: transparent;
  }

  &.prev:before,  &.next:before {
    content: ' ';
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -5px;
    margin-left: -5px;
    height: 10px;
    width: 10px;
    border-bottom: 2px solid black;
    border-left: 2px solid black;
    transform: rotate(45deg);
    transform-origin: center center;
  }

  &.next:before {
    transform: rotate(-135deg);
  }
}
