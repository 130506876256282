.p-0{padding: 0}
.p-1{padding: .25rem;}
.p-2{padding: .5rem;}
.p-3{padding: 1rem;}
.p-4{padding: 1.25rem;}
.p-5{padding: 1.5rem;}
.p-6{padding: 3rem;}

.pl-0{padding-left: 0}
.pl-1{padding-left: .25rem;}
.pl-2{padding-left: .5rem;}
.pl-3{padding-left: 1rem;}
.pl-4{padding-left: 1.25rem;}
.pl-5{padding-left: 1.5rem;}

.pr-0{padding-right: 0}
.pr-1{padding-right: .25rem;}
.pr-2{padding-right: .5rem;}
.pr-3{padding-right: 1rem;}
.pr-4{padding-right: 1.25rem}
.pr-5{padding-right: 1.5rem;}

.pt-0{padding-top: 0}
.pt-1{padding-top: .25rem;}
.pt-2{padding-top: .5rem;}
.pt-3{padding-top: 1rem;}
.pt-4{padding-top: 1.25rem}
.pt-5{padding-top: 1.5rem;}

.pb-0{padding-bottom: 0}
.pb-1{padding-bottom: .25rem;}
.pb-2{padding-bottom: .5rem;}
.pb-3{padding-bottom: 1rem;}
.pb-4{padding-bottom: 1.25rem}
.pb-5{padding-bottom: 1.5rem;}

@media#{$medium-up}{
  .p-md-0{padding: 0}
  .p-md-1{padding: .25rem;}
  .p-md-2{padding: .5rem;}
  .p-md-3{padding: 1rem;}
  .p-md-4{padding: 1.25rem;}
  .p-md-5{padding: 1.5rem;}
  .p-md-6{padding: 3rem;}

  .pl-md-1{padding-left: .25rem;}
  .pl-md-2{padding-left: .5rem;}
  .pl-md-3{padding-left: 1rem;}
  .pl-md-4{padding-left: 1.25rem;}
  .pl-md-5{padding-left: 1.5rem;}

  .pr-md-1{padding-right: .25rem;}
  .pr-md-2{padding-right: .5rem;}
  .pr-md-3{padding-right: 1rem;}
  .pr-md-4{padding-right: 1.25rem}
  .pr-md-5{padding-right: 1.5rem;}

  .pt-md-1{padding-top: .25rem;}
  .pt-md-2{padding-top: .5rem;}
  .pt-md-3{padding-top: 1rem;}
  .pt-md-4{padding-top: 1.25rem}
  .pt-md-5{padding-top: 1.5rem;}

  .pb-md-1{padding-bottom: .25rem;}
  .pb-md-2{padding-bottom: .5rem;}
  .pb-md-3{padding-bottom: 1rem;}
  .pb-md-4{padding-bottom: 1.25rem}
  .pb-md-5{padding-bottom: 1.5rem;}
}

@media#{$large-up}{
  .p-lg-0{padding: 0}
  .p-lg-1{padding: .25rem;}
  .p-lg-2{padding: .5rem;}
  .p-lg-3{padding: 1rem;}
  .p-lg-4{padding: 1.25rem;}
  .p-lg-5{padding: 1.5rem;}
  .p-lg-6{padding: 3rem;}

  .pl-lg-1{padding-left: .25rem;}
  .pl-lg-2{padding-left: .5rem;}
  .pl-lg-3{padding-left: 1rem;}
  .pl-lg-4{padding-left: 1.25rem;}
  .pl-lg-5{padding-left: 1.5rem;}

  .pr-lg-1{padding-right: .25rem;}
  .pr-lg-2{padding-right: .5rem;}
  .pr-lg-3{padding-right: 1rem;}
  .pr-lg-4{padding-right: 1.25rem}
  .pr-lg-5{padding-right: 1.5rem;}

  .pt-lg-1{padding-top: .25rem;}
  .pt-lg-2{padding-top: .5rem;}
  .pt-lg-3{padding-top: 1rem;}
  .pt-lg-4{padding-top: 1.25rem}
  .pt-lg-5{padding-top: 1.5rem;}

  .pb-lg-1{padding-bottom: .25rem;}
  .pb-lg-2{padding-bottom: .5rem;}
  .pb-lg-3{padding-bottom: 1rem;}
  .pb-lg-4{padding-bottom: 1.25rem}
  .pb-lg-5{padding-bottom: 1.5rem;}
}

