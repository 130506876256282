.promo-driver {
  position: relative;
  display: block;
  background-color: $offer-red;
  margin-bottom: 2rem;
  z-index: 1;
  width: 100%;
  box-shadow: 0 0 15px #cccccc;
}

.promo-driver--tag {
  position: absolute;
  top: 0;
  left: 0;
  max-width: calc(100% - 45px);
  min-height: 3rem;
  line-height: 1.4rem;
  z-index: 2;
  padding: 0.8rem 1rem;
  font-size: 1rem;
  background-color: currentColor;
  color: $rhino;
  text-align: left;
  text-transform: uppercase;

  &:after {
    border-bottom: 1.4rem solid transparent;
    border-left: 1rem solid currentColor;
    border-top: 1.6rem solid currentColor;
    border-right: 1rem solid transparent;
    content: ' ';
    position: absolute;
    right: -2rem;
    top: 0;
  }
}

.promo-driver--tag-text {
  color: white;
}

.promo-driver--logo {
  position: absolute;
  bottom: 0;
  right: 0;
  max-width: 90%;
  height: 3rem;
  z-index: 2;
  padding-right: 0.8rem;
  padding-left: 0.5rem;
  padding-top: 0.5rem;
  font-size: 1.1rem;
  line-height: 3rem;
  background-color: white;

  img {
    display: inline-block;
    margin: 0 auto;
    //max-height: 100%;
    max-width: 100%;
    height: 50px;
    width: auto;
    position: relative;
  }

  &:after {
    border-bottom: 1.4rem solid white;
    border-left: 1rem solid transparent;
    border-top: 2rem solid transparent;
    border-right: 1.6rem solid white;
    content: ' ';
    position: absolute;
    right: 100%;
    top: 0;
    z-index: -1;
  }
}

.promo-driver--image {
  width: 100%;
  height: 350px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  img {
    height: 100%;
  }
}

.promo-driver--content {
  position: relative;
  flex-direction: row;
  padding: 15px;
  padding-bottom: 65px;
  min-height: 70px;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  
  .promo-driver--heading {
    color: $dark-blue;
    font-size: 20px;
    line-height: 25px;
    font-family: $body-font-family;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.3px;
    margin: 0;
  }

  .promo-driver--sub-heading {
    color: black;
    font-size: 16px;
    font-style: normal;
    display: block;
    margin: 5px 0 0 0;
    font-weight: 300;
    line-height: 20px;
  }

  .promo-driver--button {
    margin: 0;
    color: white;
    width: 100%;
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
  }
}



