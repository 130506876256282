.ui-modal {
  @include stacking-context(modal);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  padding: 50px 0 0;

  &:before {
    content: ' ';
    position: absolute;
    top: -10px;
    left: 30px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent white transparent;
    z-index: 2;
  }

  @media #{$large-up} {
    width: calc(80rem - 60px);
    max-width: calc(100vw - 60px);
    height: auto;
    position: absolute;
    top: calc(100% + 15px);
    left: 0;
    padding: 0 30px 0;
    -webkit-box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.40);
    -moz-box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.40);
    box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.40);

  }
}

//.ui-modal.ui-modal--mobile {
//  display: block;
//
//  @include breakpoint(medium) {
//    display: none;
//  }
//}

.ui-modal.bg-gray {
  background-color: $very-light-gray;
}

.ui-modal--content {
  padding: 0 30px;

  @media #{$large-up} {
    margin-bottom: 80px;
    padding: 0;
  }
}

.ui-modal--simple-bar {
  height: calc(100% - 50px);
  z-index: 10;
  @media #{$large-up} {
    height: 410px;
    padding: 0;
  }
}

.ui-modal--header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #243753;
  color: white;
  height: 50px;
  z-index: 20;
  @media #{$large-up} {
    display: none;
  }
}

.ui-modal--title {
  font-size: 18px;
  font-weight: bold;
  line-height: 50px;
  margin-right: 50px;
  margin-left: 50px;
  text-align: center;
  z-index: 20;
}

.ui-modal--back {
  position: absolute;
  top: 0;
  left: 0;
  height: 50px;
  width: 50px;
  background-color: transparent;
  line-height: 50px;
  color: white;
  text-align: center;
  z-index: 30;
  vertical-align: center;
  cursor: pointer;

  &:before {
    content: ' ';
    display: inline-block;
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
    transform-origin: center center;
    border-bottom: 2px solid white;
    border-left: 2px solid white;
  }

  @media #{$large-up} {
    display: none;
  }
}

.ui-modal--close {
  position: absolute;
  top: 0;
  right: 0;
  height: 50px;
  width: 50px;
  background-color: rgb(69, 85, 109);
  line-height: 50px;
  color: white;
  text-align: center;
  z-index: 30;
  vertical-align: center;
  cursor: pointer;


  @media #{$large-up} {
    padding-right: 20px;
    width: auto;
    background-color: transparent;
    color: $dark-red;
    right: 35px;
    line-height: 80px;
    height: 80px;


    &:hover {
      color: darken($dark-red, 20%);

      &:before, &:after {
        background-color: darken($dark-red, 20%);
      }
    }
  }

  > span {
    display: none;
    @media #{$large-up} {
      display: inline-block;
      text-decoration: none;
    }
  }

  &:before, &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: 0;
    margin-left: -10px;
    width: 20px;
    height: 2px;
    display: block;
    background-color: white;

    transform: rotate(45deg);
    transform-origin: center center;

    @media #{$large-up} {
      left: 100%;
      background-color: $dark-red;
      width: 18px;
      height: 2px;
    }
  }

  &:after {
    transform: rotate(-45deg);
  }
}

.ui-modal--apply {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 50px;
  width: 100%;
  background-color: $gold;
  line-height: 50px;
  color: white;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  z-index: 30;
  cursor: pointer;

  &:hover {
    background-color: darken($gold, 15%);
  }
}

.ui-modal.select {
  padding: 50px 0 0 0;
  @media #{$large-up} {
    padding: 0 10px 0 15px;
    width: 100%;
    .ui-modal--title { display: none; }
    .ui-modal--close { display: none; }
    .ui-modal--apply { display: none; }
    .ui-modal--content { margin-bottom: 0 }
  }
}

.ui-modal.relative-width {
  @media #{$large-up} {
    width: 100%;
  }
}