@keyframes fadeIn {
  0% {
    transform: scale(0.2);
    filter: blur(10px);
    opacity: 0.2;

  }
  100% {
    transform: scale(1);
    opacity: 1;
    filter: blur(0);
  }
}

.pill {
  font-size: 12px;
  border-radius: 20px;
  overflow: hidden;
  display: inline-block;
  margin: 5px;
  border: 1px solid transparent;
  animation-name: fadeIn;
  animation-duration: 100ms;
  animation-fill-mode: forwards;

  &.no-animation {
    animation: none;
  }
}

.pill:focus {
  outline: none;
  border: 1px solid white;
  box-shadow: 0px 0px 0px 1px $gold;
}

.pill--label {
  display: inline-block;
  padding: 0 10px;
  height: 30px;
  width: auto;
  vertical-align: middle;
  line-height: 30px;
  white-space: nowrap;
  background-color: $gold;
  color: white;

}

.pill--remove {
  height: 30px;
  line-height: 30px;
  text-align: center;
  width: 30px;
  display: inline-block;
  vertical-align: middle;
  background-color: $gold;
  color: white;
  cursor: pointer;

  &:hover {
    background-color: darken($gold, 15%);
  }

  i {
    position: relative;
    top: -1px;
  }
}