$active-bg: white;
$inactive-bg: white;
$switch-bg: $navy;
$border: $medium-gray;
$transition-time: 0.15s;
$transition-type: ease-out;
$active-label-colour: white;
$label-colour: black;

.switch-wrapper {
  min-width: 260px;
  display: inline-block;

  &.small {
    min-width: 200px;
    height: 50px;
    padding-top: 8px;
    padding-bottom: 7px;
    margin-right: 20px;

    .switch{
      height: 35px;
      border-radius: 20px;
    }

    .switch--label {
      line-height: 35px;
      font-size: 10px;
    }

    .switch--toggle {
      height: 40px;

       &:before{
        height: 28px;
      }
    }
  }
}

.switch {
  max-width: 280px;
  height: 50px;
  position: relative;
  overflow: hidden;
  display: inline-block;
  border-radius: 25px;
  cursor: pointer;
  width: 100%;
  margin: 0;
  border: 1px solid $border;
}

.switch--input{
  display: none;
}

.switch--label-container {
  position: absolute;
  top: 0;
  z-index: 5;
  width: 100%;
  display: flex;
  flex-direction: row;
  pointer-events: none;
}

.switch--label {
  line-height: 50px;
  font-family: sans-serif;
  text-transform: uppercase;
  width: 50%;
  text-align: center;
  pointer-events: none;
  color: $active-label-colour;
  font-weight: 600;
  //transition: $transition-time color $transition-type;
}

.switch--label + .switch--label {
  color: $label-colour;
  font-weight: 300;
}

.switch--toggle {
  display:block;
  width: 100%;
  height: 50px;
  background-color: $inactive-bg;
  transition: $transition-time background-color $transition-type;
}

.switch--toggle:before {
  content: '';
  z-index: 4;
  position: absolute;
  width: calc(50% - 6px);
  height: 42px;
  top: 3px;
  left: 3px;
  right:auto;
  border-radius: 25px;
  background-color: $switch-bg;
  transition: $transition-time all $transition-type;
}

.switch--input:checked  {
  & ~ .switch .switch--label-container {
    .switch--label {
      color: $label-colour ;
      font-weight: 300;
    }

    .switch--label + .switch--label {
      color: $active-label-colour;
      font-weight: 600;
    }
  }

  & ~ .switch .switch--toggle {
    background-color: $active-bg;

    &:before {
      left: calc(50% + 3px);
    }
  }
}

.switch--toggle:focus {
  outline: none;

  &:before {
    content: ' ';
    position: absolute;
    left: -3px;
    top: -3px;
    border-radius: 20px;
    height: calc(100% + 6px);
    width: calc(100% + 6px);
    border: 1px solid $gold;
  }
}
