.background-image-style-one {
  background-image: url('/images/background-image-style-one.png');
  background-repeat: no-repeat;

  background-color: $light-gray-2;
  .image-only {
    padding: 2rem 0;
  }
  .image-with-text {
    .button {
      text-transform: uppercase;
      &.white {
        background: none;
      }
    }
  }

  .divider-container {
    &.centered-image {
      margin: 0;
      img {
        top: rem-calc(-18);
        margin-left: rem-calc(-18);
      }
    }
  }

  .pull-up{
    margin-top: rem-calc(-40);
  }
}

.background-no-image-light-gray {
  background-color: $light-gray-2;
}

.background--style-tips {
  background-color: #f5ebdf;
}

.background--style-steps {
  background-color: white;
  border: 1px solid $gold;
  padding-left: 1rem;
  padding-right: 1rem;
  @media #{$large-up}{
    margin-left: 1.5rem;
  }
}

#award{
  position: relative;
  background: #b6373d url("/images/ro-bgnd.png") no-repeat right;

  .divider-container {
    z-index: 9;
    margin: 0;
    top: -1.1rem;
    @media#{$small-only}{

      img {
        top: 0;
      }

    }
  }
  h2, h3, h4, h5, p{
    color: $white;
  }
  h2, h3, h4 ,h5{
    margin-bottom: 0;
  }
  ul.centered-list{
    margin-top: 0.5rem;
    li{
      margin: 0 0.25rem;
    }
  }
  .background-image-split {
    position: absolute;
    width: 50%;
    top: 0;
    left: 0;
    bottom: 0;
    background: url('/images/cruise-academy-background.jpg') 50% 50% no-repeat;
    @media#{$small-only}{
      position: relative;
      width: 100%;
    }
  }
}

