.quantity {
  background-color: white;
  border: 1px solid grey;
  display: flex;
  flex-direction: row;
  height: 50px;
}

.quantity-label {
  width: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 26px;
  cursor: pointer;
}

.quantity-input {
  width: 100%;
  line-height: 50px;
  text-align: center;
  border-left: 1px solid grey;
  border-right: 1px solid grey;
}