.search-field {
  width: 100%;
  height: 80px;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 20px;

  &.condensed {
    height: auto;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;

    @media #{$large-up} {
      flex-direction: column;
      justify-content: flex-end;
      align-items: start;
    }
  }
}

.search-field--clear {
  cursor: pointer;
  user-select: none;
  position: absolute;
  right: 20px;
  bottom: 17px;
  height: 16px;
  width: 16px;
  color: $rhino;

  &:before, &:after {
    content: '';
    background: currentColor;
    transform-origin: center;
    position: absolute;
  }

  &:before {
    height: 3px;
    width: 100%;
    transform: translateY(-50%) rotate(-45deg);
    top: 50%;
  }

  &:after {
    width: 3px;
    height: 100%;
    transform: translate(-50%, -50%) rotate(-45deg);
    top: 50%;
    left: 50%;
  }

  &:hover {
    color: #681f23;
  }
}

.search-field--group {
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 50px;
  height: 50px;
  width: 100%;

  input {
    margin: 0;
  }
}
.search-field--input {
  background-color: white;
  height: 50px;
  line-height: 48px;
  font-size: 14px;
  border: 1px solid $medium-gray;
  font-weight: 600;
  color: $charcoal;
  padding: 0 35px 0 15px;
  width: 100%;
  letter-spacing: 0.3px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: all 300ms;

  &.disabled {
    opacity: 0.5;
    cursor: default;
  }

  &.active, &.focus {
    border: 1px solid $gold;
  }

  &:hover {
    color: black;

    i {
      color: black;
    }
  }

  i {
    line-height: 48px;
    text-align: center;
    display: inline-block;
    padding-right: 6px;
    color: $dark-gray;
    transition: all 300ms;
  }

  &:after {
    position: absolute;
    right: 0;
    top: 0;
    width: 50px;
    height: 48px;
    text-align: center;
    line-height: 48px;
    content: '\F078';
    font-family: 'fontawesome';
    transform: rotate(-90deg);

    @media #{$large-up} {
      transform: rotate(0deg);
    }
  }
}

.search-field--label {
  line-height: 25px;
  height: 25px;
  margin: 0;
  margin-bottom: 5px;
  font-size: 14px;
  letter-spacing: 0.3px;
  font-weight: 400;
  color: $dark-charcoal;
}