@import "../../../../../node_modules/@glidejs/glide/src/assets/sass/glide.core";
//@import "../../../../../node_modules/@glidejs/glide/src/assets/sass/glide.theme";

.glide__slides {
  margin: 0;
}

.glide__controls {
  position: absolute;
  //width: calc(100% + 30px);
  //left: -15px;
  //top: calc(50% - 0.5rem);
  left: 0;
  right: 0;
  //width: 100%;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  pointer-events: none;

  button {
    pointer-events: all;
    width: 20px;
  }

  button .icn{
    font-size: 1.4rem;
  }

  button:hover .icn {
    font-size: 1.8rem;
  }

  button:focus {
    outline: none;
  }

  button[data-glide-dir=">"] i:before{

  }
}

.glide__bullets {
  display: flex;
  justify-content: center;
  pointer-events: none;
}

.glide__bullet {
  pointer-events: all;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: #000;
  opacity: 0.5;
  margin: 0 5px;
  transition: all 0.2s ease;
}