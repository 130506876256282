.hamburger {
  @include stacking-context(default);
  cursor: pointer;
}

.hamburger--box {
  height: 3px * 3 + 6px * 2;
  display: inline-block;
  position: relative;
  width: 21px;
}

.hamburger--inner {
  display: block;
  top: 50%;
  margin-top: 3px / -2;
  right: 0px;
  transition-duration: 0.1s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

  &, &::before, &::after {
    width: 30px;
    height: 3px;
    background-color: currentColor;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }

  &::before, &::after {
    content: "";
    display: block;
  }

  &::before {
    transition: top 0.1s 0.14s ease, opacity 0.1s ease;
    top: (6px + 3px) * -1;
  }

  &::after {
    transition: bottom 0.1s 0.14s ease, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    bottom: (6px + 3px) * -1;
  }
}

.hamburger.open .hamburger--inner {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    transition-delay: 0.14s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

    &::before {
      top: 0;
      opacity: 0;
      transition: top 0.1s ease,
      opacity 0.1s 0.14s ease;
    }

    &::after {
      bottom: 0;
      -webkit-transform: rotate(-90deg);
      transform: rotate(-90deg);
      transition: bottom 0.1s ease,
      transform 0.1s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
}

