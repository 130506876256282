.facet {
  padding: 0;

  &--relative {
    position: relative;
  }
}

.facet.in-modal{
  @media #{$large-up} {
    .facet--header { line-height: 80px; }
    .facet--clear {
      right: 20px;
      line-height: 80px;
      padding: 0 15px;
      margin-right: 10px;
      text-decoration: none;
      &:after{
        content: '';
        height: 14px;
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -7px;
        width: 1px;
        background-color: $dark-gray;
      }
    }
  }
}

.facet--header {
  width: 100%;
  position: relative;
  display: block;
  vertical-align: center;
  margin-bottom: 0;
  line-height: 50px;
}

.facet--heading {
  margin: 0;
  line-height: inherit;
  font-weight: 900;
  font-size: 16px;
  font-style: normal;
  font-family: $body-font-family;
}

.facet--clear {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  color: $dark-charcoal;
  text-decoration: underline;
  line-height: inherit;

  &:hover {
    color: darken($dark-red, 20%);
  }

  @media #{$large-up} {
    right: 0;
  }
}

/*.panel .facet--clear {
  right: 0;
  padding: 0;
  margin: 0;

  &:after {
    display: none;
  }
}*/

.facet--list {
  padding: 0 3px;
  margin: 0;
  list-style: none;

}

.facet--other {
  padding: 0 3px;
  margin-top: 10px;
}

.facet--item {
  opacity: 0.3;
  margin: 0;
  padding-right: 10px;
  width: 100%;
  display: inline-block;
  break-inside: avoid-column;
  @media #{$medium-up} {  width: 50%;  }
  @media #{$large-up} {  width: 25%; }

  &.active {
    opacity: 1;
  }

  &.focus {
    background-color: rgba(7A,0A,1B, 0.1);
    background-size: 100% calc(100% - 10px);
    background-position: center center;
  }
}

.facet--tag-container {
  white-space: nowrap;
  padding: 0;

  span.tag {
    margin: 5px;
    display: inline-block;
  }
}

.facet--simple-bar-horizontal {
  max-width: 100%;
  margin-bottom: 20px;
}

.facet--simple-bar {
  position: relative;
  left: -3px;
  right: -3px;

  max-height: calc(100vh - 270px);

  @media #{$large-up} {
    max-height: 240px;
  }
}

.search-input ~ .facet--simple-bar {
  @media #{$large-up} {
    max-height: 200px;
  }
}

.facet--simple-bar-horizontal ~ .search-input ~ .facet--simple-bar {
  max-height: 240px;

  @media #{$large-up} {
    max-height: 150px;
  }
}

.facet.select {
  @media #{$large-up} {
    padding-top: 15px;
    padding-bottom: 15px;
    .facet--simple-bar { max-height: 380px }
    .facet--header { display: none }
  }
}

.facet.column-count-1 {
  .facet--item { display:block; width: 100%; }
}

