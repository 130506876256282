.shortlist-count {
  height: 20px;
  width: 20px;
  background-color: $gold;
  color: white;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  font-size: 12px;
  margin-left: 6px;
}

a:hover .shortlist-count {
  background-color: $navy;
}
