input.search-input, .search-input {
  background-color: white;
  font-size: 16px;
  color: black;
  height: 40px;
  line-height: 40px;
  border-radius: 20px;
  border: 1px solid $light-gray;
  padding: 0 20px;
  padding-right: 60px;
  position: relative;
  box-shadow: none;

  background-image: url(/images/icons/icon-search.svg);
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: calc(100% - 20px) center;

  &:hover {
    border: 1px solid $gold;
  }

  &:hover::placeholder {
    color: $light-charcoal;
  }

  &::placeholder {
    color: $dark-gray;
    font-style: italic;
    transition: all 300ms;
  }

  &:after {
    content: "\F002";
    font-family: 'fontawesome';
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
  }
}