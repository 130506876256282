.feefo-review {
  background-color: white;
  padding: 2rem;
  margin: 1rem 0;
  position: relative;
}

.feefo-review--heading {
  font-family: $body-font-family;
  font-style: normal;
  font-weight: bold;
  font-size: 1.3rem;
  line-height: 1.5rem;
  color: $slate-navy;
}

.feefo-review--text {
  color: $charcoal;
}

.feefo-review--rating, .review-stars {
  color: #ffcf03;
  width: 110px;
}

.feefo-embed--review-heading {
  font-family: $body-font-family;
  font-style: normal;
  font-weight: bold;
  line-height: 25px;
  padding-top: 0.5rem;
  color: $slate-navy;
  display: inline-block;
}

.feefo-embed--review-link {
  width: 100%;
  display: block;
  text-align: center;
  margin: 0;
  line-height: 25px;

  @media #{$large-up} {
    width: auto;
    text-align: inherit;
    display: inline-block;
    margin-left: 2rem;
    line-height: inherit;
  }
}

.feefo-review--thread {
  position: absolute;
  right: calc(2rem + 7px);
  bottom: 2rem;
  color: $gold;
}

.feefo-review + .feefo-review--comment {
  margin-top: -2rem;
}

.feefo-review--comment {
  background-color: $navy;
  color: white;
  padding: 2rem;
  position: relative;

  &:before {
    content: ' ';
    position: absolute;
    top: 0;
    right: 2rem;
    border: 15px solid transparent;
    border-top: 15px solid white;
    z-index: 10;
  }

  &.customer {
    background-color: white;
    color: black;
    &:before {
      border-top: 15px solid $navy;
    }
  }

}

.feefo-review--author {
  color: $gold;
  text-align: right;
  margin-bottom: 0;
}

.feefo-embed-homepage {
  background-color: transparent !important;
  position: relative;

  h4 {
    font-weight: normal;
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
}

.feefo-review-modal.reveal {
  max-height: 90vh;
  overflow-y: scroll;

  .close-button {
    position: sticky;
    float: right;
    z-index: 100;
    background-color: $gold;
    color: white;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    line-height: 30px;
    padding: 0;
    margin: 0;
    margin-bottom: -30px;
    text-align: center;

    &:hover {
      background-color: $navy;
    }
  }
}
