.heading-section{
    
    p{
        color: $light-navy;
        font-size: rem-calc(18);

        &.gold {
          color: #cf9d63;
        }
    }
    
    .incopy-secondary-cta {
        
        p.cta-line {
            text-align: center;
            font-family: $header-font-family;
            font-style: italic;
            font-size: 2rem;
            color: $navy;
            
            span {
                text-transform: uppercase;
                font-family: $body-font-family;
                font-style: initial;
                font-weight: 600;
              
            }
            
        }
    }
    
    &.with-featured-video {
         
         .coupon-inner-container {
                position: relative;
                margin-bottom: 1.33333rem;
                
                i {
                    width: auto;
                    left: 50%;
                    margin-left: -1.86667rem;
                    position: absolute;
                    top: 50%;
                    -webkit-transform: translateY(-50%);
                    -ms-transform: translateY(-50%);
                    transform: translateY(-50%);
                    color: #fefefe;
                    font-size: 450%;
                    z-index: 10;
                }
                
                hr {
                    width: 20%;
                    margin-top: 0.25rem;
                    margin-bottom: 0.5rem;
                }
                
                
                img {
                    width: 100%;
                }
                

                .coupon-info {
                        @include linear-gradient($transparent, rgba($black, 0.65));
                        padding: 4rem 1rem 6rem 1rem;
                        position: absolute;
                        bottom: 0;
                        width: 100%;
                        
                        h3 {
                            color: $white;
                            
                        }
                }
         }
         
        .flex-video {
            margin-bottom: 0;
         }
        
         
    
    }
    
    .long-heading {
    
          @media#{$large-up}{
            padding-left: 12rem;
            padding-right: 12rem;
        }
        
        
        h2 {
            line-height: 1.2;
        }
        
        @media#{$medium-only}{
            padding-left: 3rem;
            padding-right: 3rem;
        }
        
          @media#{$small-only}{
            padding-left: 2rem;
            padding-right: 2rem;
        }
        
        
        
        
    }
}

.with-secondary-cta {
    position: relative;
    
    .add2Shortlist-wrapper {
        position: absolute;
        right: 1rem;
        bottom: 0;
        margin-bottom: 10px;
      .button {
        margin: 0;
      }
    }
    
      @include breakpoint(small only) {
          
            .add2Shortlist-wrapper {
               position: relative;
               right: inherit;
            }
      }
}

.shortlist-wrapper {
  display: flex;
  margin-left: auto;
  align-items: center;

  .button {
    margin: 0;
  }
}