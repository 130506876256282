@import "utility/flex";
@import "utility/margin";
@import "utility/padding";
@import "utility/layout";
@import "utility/position";
@import "utility/widths";
@import "utility/heights";
@import "utility/display";
@import "utility/colours";

.no-scroll {
  max-height: 100vh;
  overflow: hidden;
  //position: fixed;
}

.no-scroll-md-down {
  @media only screen and(max-width: 1023px) {
    max-height: 100vh;
    max-width: 100vw;
    overflow: hidden;
    position: fixed;
  }
}