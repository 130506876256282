.unique-selling-proposition {
  display: none;
  width: 100%;
  justify-content: center;
  background: #DFDEDE;
  opacity: 0.9;
  @media #{$large-up} {
    display: block;
  }
  .unique-selling-proposition--inner-container {
    justify-content: center;
    display: inline-flex;
    width: 100%;
    div {
      margin: 1rem 0;
      padding: 0 0.5rem;
      color: #222;
      border-right: #222 1px solid;
    }
    div:last-child {
      border-right: none;
    }
  }
}