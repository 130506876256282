.promo-grid {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  flex-wrap: wrap;

  @media#{$large-up}{
    display: grid;
    display: -ms-grid;
    grid-gap: 15px;

    -ms-grid-columns: 1fr 15px 1fr 15px 1fr;
    -ms-grid-rows: 220px 15px 220px 15px 220px;

    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 220px 220px 220px;
  }

  &__short {
/*    @media#{$medium-up}{
      -ms-grid-columns: 1fr 15px 1fr;
      -ms-grid-rows: 230px 15px 230px;
      grid-template-columns: 1fr 15px 1fr;
      grid-template-rows: 230px 230px;
    }*/

    @media#{$large-up}{
      -ms-grid-columns: 1fr 15px 1fr 15px 1fr;
      -ms-grid-rows: 220px 15px 220px;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 220px 220px;
    }
  }

  &--driver:first-child {
    -ms-grid-row-span: 3;
    -ms-grid-column-span: 3;
    -ms-grid-column: 1;

    grid-row-start: 1;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 3;

    .promo-grid--driver-info {
      justify-content: center;

      .promo-grid--driver-cruise {
        width: 120px;
        height: auto;
        margin: 0 auto 0;

        &-padded {
          margin: -1rem auto 0;
        }
      }
    }

    .promo-grid--driver-cruise-mobile {
      margin: 0;
      position: absolute;
      top: 1rem;
      right: 1rem;
      width: 120px;
      height: auto;

      &-padded {
        top: 0;
      }

      @media #{$large-up} {
        display: none;
      }
    }

    .promo-grid--driver-btn {
      @media #{$large-up} {
        display: none;
      }
    }
  }

  &--driver:nth-child(n + 2) {
    .promo-grid--driver-padded {
      top: 0 !important;
    }

    .promo-grid--driver-cruise {
      width: 120px;
      height: auto;
      position: absolute;
      top: 1rem;
      right: 1rem;
    }
  }

  &--driver:nth-child(2) {
    -ms-grid-column: 5;
    -ms-grid-row: 1;
  }

  &--driver:nth-child(3) {
    -ms-grid-column: 5;
    -ms-grid-row: 3;
  }

  &--driver:nth-child(4) {
    -ms-grid-column: 1;
    -ms-grid-row: 5;
  }

  &--driver:nth-child(5) {
    -ms-grid-column: 3;
    -ms-grid-row: 5;
  }

  &--driver:nth-child(6) {
    -ms-grid-column: 5;
    -ms-grid-row: 5;
  }

  &--driver {
    @include stacking-context(default);
    margin: 5px;
    display: block;
    width: 100%;
    height: 203px;
    overflow: hidden;

    @media#{$medium-up}{
      width: calc(50% - 10px);
    }

    @media#{$large-up}{
      margin: 0;
      width: auto;
      height: auto;
    }

    h5 {
      color: white;
    }

    picture {
      @include stacking-context(default, behind);
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &:hover {
      .promo-grid--driver-btn {
        background-color: $navy;
        border-color: $navy;
      }
    }
  }

  &--driver-info {
    @include stacking-context(default, infront);

    @media#{$large-up}{
      justify-content: flex-end;
    }

    position: absolute;
    padding: 25px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &--driver-overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background: rgba($dark-charcoal, 0.7);
    padding: 1rem;
    width: 30%;
    flex-direction: column;
    justify-content: space-between;
    display: none;

    @media #{$large-up} {
      display: flex;
    }
  }

  &--driver-btn {
    transition: all 0.25s ease-out;
    text-transform: none;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba($dark-charcoal,0.7);
    color: white;
    padding: 0.5em;
    font-family: $header-font-family;
    font-weight: $header-font-weight;
    line-height: 24px;
    font-size: 1.2rem;
    overflow: hidden;

    &:not(.no-icon):after {
      content: '\f178';
      font-family: FontAwesome;
      margin-left: 5px;
    }
  }

  &--driver-countdown {
    position: absolute;
    color: white;
    font-size: 35px;
    font-weight: 600;
    letter-spacing: 3px;
    margin-bottom: 1.2rem;
    padding: 0 1rem;

    @media #{$large-up} {
      display: flex;
      flex-direction: row;
      position: relative;
      font-size: 24px;
    }

    .item {
      display: flex;
      flex-direction: column;
      position: relative;
      font-size: 18px;
      font-weight: 700;
      letter-spacing: 1px;
      text-transform: uppercase;
      width: 30%;
    }

    .day:after, .hour:after, .minute:after {
      font-size: 11px;
      font-weight: 500;
    }

    .day:after {
      content: 'days';
    }

    .hour:after {
      content: 'hours';
    }

    .minute:after {
      content: 'mins';
    }

    .second {
      display: none;
    }

    .countdown-divider {
      font-size: 18px;
    }

    &:before {
      content: 'Offer ends...';
      position: absolute;
      top: -15px;
      font-size: 11px;
      text-transform: uppercase;
      left: calc(50% - 25%);
      font-weight: 500;
    }
  }

  &--promo-grid--driver-countdown, .expired {

    &:before {
      content: '';
    }

    .day:after, .hour:after, .minute:after {
      content: '';
    }
  }

  &--driver-info h3 {
    color: inherit;
    margin-bottom: 15px;

    &:after {
      content: '';
      width: 80px;
      height: 1px;
      background-color: white;
      margin: 10px auto 0px;
      display: block;
    }
  }
}

