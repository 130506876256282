.radio {
  height: 50px;
  line-height: 50px;
  padding: 10px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  cursor: pointer;
}

.radio--box {
  height: 26px;
  width: 26px;
  border-radius: 50%;
  margin-right: 10px;
  background-color: white;
  border: 1px solid $light-gray;
  text-align: center;
  line-height: 20px;
  transition: all 300ms;
  position: relative;
}

.radio--label {
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.5px;
  line-height: 30px;
  width: calc(100% - 40px);
  margin: 0;
}

.radio:not(.active):hover .radio--box:before {
  content: '';
  height: 14px;
  width: 14px;
  border-radius: 50%;
  position: absolute;
  display: block;
  background-color: $medium-gray;

  top: 50%;
  left: 50%;
  margin-top: -7px;
  margin-left: -7px;
}


.radio.active .radio--box {
  //background-color: white;
  //border-color: $gold;
  //color: white;

  &:before {
    content: '';
    height: 14px;
    width: 14px;
    border-radius: 50%;
    position: absolute;
    display: block;
    background-color: $gold;

    top: 50%;
    left: 50%;
    margin-top: -7px;
    margin-left: -7px;
  }
}

.radio.active .radio--label {
  font-weight: 500;
}