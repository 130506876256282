.upper-footer{
    background: $slate-navy;
    padding: 1rem 0rem;
    width: 100%;
    @media#{$large-up}{
        padding: 4rem 0rem;
    }
}
.trustpilot-widget {
    padding-bottom: 20px;
}
.middle-footer {
    background: $navy;
    width: 100%;
    padding: 2rem 0 2rem 0;
    @media#{$large-up}{
        padding: 3rem 0;
    }
    h5 {
        font-family: $body-font-family;
        border-bottom: rem-calc(1) solid $light-slate-navy;
        text-transform: uppercase;
        padding: 0.5rem 0;
        font-size: rem-calc(18);
        font-style: normal;
        color: $white;
        text-align: center;
        width: 100%;
        float: left;

        img{
            margin-left: rem-calc(5);
            transition: all 500ms;
        }

        @media#{$large-up}{
            text-align: left;
            font-family: $body-font-family;
            margin-bottom: 1rem;
            font-size: rem-calc(18);
            font-style: normal;
            color: $white;

            border: none;
            img {
                display: none;
            }
        }

        &.active img {
            transform: rotate(180deg);
        }
    }
    .footer-menu {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        width: 100%;
        //margin-top: 0.5rem;
        margin-left: 0;

        @media#{$large-up}{
            padding-right: 3rem;
        }

        &--section {
            flex: 1 0 100%;

            @media#{$large-up}{
                flex: 1 0 33%;
            }
        }

        &--link {
            color: $white;
            text-transform: none;
            font-size: 1rem;
            border: none;
            padding: .5rem 0;
            width: 100%;
            display: block;
            text-align: center;


            @media#{$large-up}{
                text-align: left;
            }

            &:hover {
                color: $gold;
            }

            &.hide {
                display: none !important;
                @media#{$large-up} {
                    display: block !important;
                }
            }
        }
    }

    form{
        input[type=text],
        input[type=email],
        textarea{
            border: rem-calc(1) solid $light-slate-navy;
            height: rem-calc(38);
            min-height: rem-calc(38);
            overflow-y:hidden;
            background: none;
            margin-bottom: 0.75rem;
        }
        input[type=submit]{
            border: rem-calc(1) solid $light-slate-navy;
            background: $light-slate-navy;
            text-transform: uppercase;
            height: rem-calc(38);
            color: $white;
            width: 100%;
        }
    }
}

.review-slider {
    position: unset;
}

.footer--awards {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    @media #{$medium-up} {
        flex-direction: row;
    }
}

.lower-footer {
    &.by-accord{
        padding: 0.5rem 0;
        a{
            color: white;
            &:hover{
                text-decoration: underline;
            }
        }
    }
    //border-top: rem-calc(1) solid $light-slate-navy;
    background: $navy;
    //padding: 2rem 0 0;
    width: 100%;
    text-align: center;

    @media#{$large-up}{
        text-align: left;
    }

    .rol-icon{
        float: none;
        margin-right: 0;
        display: inline-block;
        max-width: 140px;
        height: 76px;

        @media#{$large-up}{
            margin-right: 2rem;
            float: left;
        }
        img{
            float: left;
            width: rem-calc(105);
        }
    }
    p{
        margin-top: 1rem;
        color: $white;
        font-size: 75%;
    }
    .accreditation{
        .inner-container{
            margin-top: 1rem;
            float: none;
            text-align: center;
            @media#{$large-up}{
                float: right;
                text-align: right;
            }
            img{
                margin-left: 1rem;
            }
        }
    }
}

.footer--confidence {
    background-color: white;
    text-align: center;
    padding: 40px 0;
}

.footer--confidence-drivers {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    @media#{$medium-up}{
        flex-direction: row;
    }
}

.footer--confidence-driver {
    flex: 1 0 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: auto auto 10px;
    align-items: center;

    @media#{$medium-up}{
        flex: 1 0 50%;
    }

    @media#{$large-up}{
        flex: 1 0 25%;
        flex-direction: row;
        text-align: left;
        margin: 0 0 10px;
    }

    img {
        width: 40px;
        height: 40px;
    }
}

.footer--confidence-driver span {
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    color: $navy;
    letter-spacing: 0.3px;


    @media#{$large-up}{
        padding-right: 25px;
    }
}

.footer--confidence-driver img {
    margin: 20px 0;
    width: 40px;
    height: 40px;

    @media#{$large-up}{
        margin-right: 20px;
    }
}

.feefo-embed-image {
    clear: both;
    width: 180px;
    height: 46px;
    line-height: 46px;
    display: inline-block;
    border-radius: 6px;
    background-color: #ffd000;
    text-align: center;
    vertical-align: middle;
}

.footer--site-search {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
    max-width: 340px;
    position: relative;
    margin: 0 auto 3rem;

    @media #{$large-up} {
        max-width: none;
    }

    &-btn {
        display: inline-block;
        width: 60px;
        line-height: 20px;
        text-align: center;
        color: $white;
        background: $gold;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
        margin: 0;

        @media #{$medium-up} {
            width: 120px;
        }
    }

    &-input {
        display: inline-block;
        height: 50px;
        flex: 0 0 100%;
        color: black;
        border: 1px solid $very-light-gray;
        border-radius: 25px;
        margin: 0;
        box-shadow: none;
        font-size: 1rem;
        padding: 0 70px 0 1rem;

        @media #{$medium-up} {
            padding: 0 120px 0 1rem;
        }

        &::placeholder {
            color: $dark-gray;
            font-weight: 300;
        }

        &:focus, &:focus-within {
            border: none;
            outline: none;
            box-shadow: none;
        }
    }

}