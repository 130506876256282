@mixin button($color, $text: white){
  background: $color;
  border: 3px solid $color;
  color:  $text;
  transition: background-color 0.25s ease-out, border 0.25s ease-out, color 0.25s ease;

  &.text-black {
    color: black;

    &:before, &:after {
      border-color: black;
    }
  }

  &.secondary{
    background-color: transparent;
    color: $color;

    &.text-black {
      color: black;
    }

    &:hover {
      border: 3px solid $dark-blue;
      background-color: white;
      color: $dark-blue;
    }
  }

  &.white-bg {
    background-color: white;
    color: $color;

    &.text-black {
      color: black;
    }

    &:after, &:before {
      border-color: $color;
    }
  }

  &:hover {
    border: 3px solid $dark-blue;
    background-color: $dark-blue;
    color: white;

  }
}


.button {
  text-transform: uppercase;
  display: inline-block;
  text-align: center;
  //line-height: 1;
  line-height: 1.6;
  cursor: pointer;
  -webkit-appearance: none;
  vertical-align: middle;
  border-radius: 0;
  //padding: 0.85em 1em;
  padding: 0.5em 1em;
  margin: 0 0 1rem 0;
  font-size: 1rem;
  position: relative;
  white-space: nowrap;
  @include button($gold);

  &.no-margin {
    margin: 0;
  }

  &.margin-sides {
    margin-left: 10px;
    margin-right: 10px;
  }

  &.form-btn {
    line-height: 35px;
    height: 39px;
    padding: 0 2rem;
  }

  &:not(.no-icon):after {
    content: '\f178';
    font-family: FontAwesome;
    margin-left: 5px;
  }

  &.white{
    @include button($white, black);
  }

  &.blue {
    @include button($dark-blue);

    &.hover-darken:hover {
      border: 3px solid darken($dark-blue, 15%);
      background-color: darken($dark-blue, 15%);
    }
  }

  &.red {
    @include button($dark-red);

    &.hover-darken:hover {
      border: 3px solid darken($dark-red, 15%);
      background-color: darken($dark-red, 15%);
    }
  }

  &.expand-always {
    width: 100%;
  }

  &.expanded {
    width: 100%;
  }

  &.expand-mobile {
    width: 100%;
    
    @include breakpoint(medium) {
      width: auto;
    }
  }

  &.wrap {
    white-space: normal;
  }

  @media#{$medium-up}{
    &.expand {
      width: auto;
    }
  }
}