@import "./embed-container";

.cruise-upsell {
  // defaults
  &--section {
    padding: 1rem 0;
    position: relative;
  }

  .columns {
    @include breakpoint(large down) {
      margin-bottom: 2rem;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  a:not(.button) {
    text-decoration: underline;
  }

  .read-more-link {
    color: $gold;
    text-decoration: underline;
    cursor: pointer;
  }

  // container
  &--contained { max-width: 80rem; margin: 0 auto; }
  &--fullWidth { max-width: none; }

  // row
  &--row {
    margin: 0 auto;

    &:before, &:after {
      content: '';
      display: table;
    }

    &:after {
      clear: both;
    }
  }

  &--row-contained { max-width: 80rem; };
  &--row-fullWidth { max-width: none };

  // themes
  &--white {
    background-color: white;

    * { color: inherit; }
  }

  &--gray {
    background-color: $light-gray-2;

    * { color: inherit; }
  }

  &--blue {
    background-color: $dark-blue;
    color: white;

    * { color: inherit; }
  }

  // background image
  &--bg-img {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    .cruise-upsell--row {
      z-index: 1;
      position: relative;
    }

    &.cruise-upsell--blue {
      &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background: rgba($dark-blue, 0.4)
      }
    }

    &.cruise-upsell--gray {
      &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background: rgba($light-gray-2, 0.4)
      }
    }

    &.cruise-upsell--white {
      &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background: rgba(white, 0.4)
      }
    }
  }

  // paddings
  &--1x { padding: 1rem 0; }
  &--2x { padding: 2rem 0; }
  &--3x { padding: 3rem 0; }
  &--4x { padding: 4rem 0; }

  // alignment
  &--left { text-align: left; }
  &--right { text-align: right; }
  &--centre {
    text-align: center;

    &:after {
      margin: 17px auto !important;
    }
  }

  &--image-collection {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;

    &-item {
      &:first-of-type {
        grid-column: span 2;
      }

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  &--text-underline {
    text-decoration: underline;
  }
}