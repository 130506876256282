@mixin vertical-align($position: relative) {
    position: $position;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

@mixin rotate( $degrees ) {
    -webkit-transform: rotate(#{$degrees}deg);
    -moz-transform: rotate(#{$degrees}deg);
    -ms-transform: rotate(#{$degrees}deg);
    -o-transform: rotate(#{$degrees}deg);
    transform: rotate(#{$degrees}deg);

    filter:  progid:DXImageTransform.Microsoft.Matrix(sizingMethod='auto expand', M11=#{cos($degrees)}, M12=-#{sin($degrees)}, M21=#{sin($degrees)}, M22=#{cos($degrees)});
    -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(sizingMethod='auto expand', M11=#{cos($degrees)}, M12=-#{sin($degrees)}, M21=#{sin($degrees)}, M22=#{cos($degrees)})";
    zoom: 1;
}

@mixin linear-gradient($fromColor, $toColor) {
    background: -moz-linear-gradient(top, $fromColor 0%, $toColor 100%);
    background: -webkit-linear-gradient(top, $fromColor 0%, $toColor 100%);
    background: linear-gradient(to bottom, $fromColor 0%, $toColor 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$fromColor}', endColorstr='#{$toColor}',GradientType=0 );
}

@mixin rounded-corners($radius) {
    border-radius: $radius;
    -moz-border-radius: $radius;
    -webkit-border-radius: $radius;
}

@mixin box-shadow($top, $left, $blur, $color, $inset:"") {
    -webkit-box-shadow:$top $left $blur $color #{$inset};
    -moz-box-shadow:$top $left $blur $color #{$inset};
    box-shadow:$top $left $blur $color #{$inset};
}

@mixin scrollable() {
    overflow: hidden;

    &.scroll-x {
        overflow-x: auto;
    }

    &.scroll-y {
        overflow-y: auto;
    }
}