picture {
  .sharpen {
    animation: sharpen .5s both;
    animation-iteration-count: 2;
  }

  .blur {
    filter: blur(20px);
  }

  img {
    object-fit: cover;
    width: 100%;
  }
}

@keyframes sharpen {
  from { filter: blur(20px); }
  to { filter: blur(0px); }
}
