.m-a{margin: auto}
.m-0{margin: 0}
.m-1{margin: .25rem;}
.m-2{margin: .5rem;}
.m-3{margin: 1rem;}
.m-4{margin: 1.25rem;}
.m-5{margin: 1.5rem;}
.m-a{margin: auto}

.mx-a{margin-left: auto; margin-right: auto;}

.ml-a{margin-left: auto}
.ml-0{margin-left: 0}
.ml-1{margin-left: .25rem;}
.ml-2{margin-left: .5rem;}
.ml-3{margin-left: 1rem;}
.ml-4{margin-left: 1.25rem;}
.ml-5{margin-left: 1.5rem;}

.mr-a{margin-right: auto}
.mr-0{margin-right: 0}
.mr-1{margin-right: .25rem;}
.mr-2{margin-right: .5rem;}
.mr-3{margin-right: 1rem;}
.mr-4{margin-right: 1.25rem}
.mr-5{margin-right: 1.5rem;}

.mt-a{margin-top: auto}
.mt-0{margin-top: 0}
.mt-1{margin-top: .25rem;}
.mt-2{margin-top: .5rem;}
.mt-3{margin-top: 1rem;}
.mt-4{margin-top: 1.25rem}
.mt-5{margin-top: 1.5rem;}

.nmt-0{margin-top: -0}
.nmt-1{margin-top: -0.25rem;}
.nmt-2{margin-top: -0.5rem;}
.nmt-3{margin-top: -1rem;}
.nmt-4{margin-top: -1.25rem}
.nmt-5{margin-top: -1.5rem;}
.nmt-15{margin-top: -15rem;}

.mb-a{margin-bottom: auto}
.mb-0{margin-bottom: 0}
.mb-1{margin-bottom: .25rem;}
.mb-2{margin-bottom: .5rem;}
.mb-3{margin-bottom: 1rem;}
.mb-4{margin-bottom: 1.25rem}
.mb-5{margin-bottom: 1.5rem;}

@media#{$medium-up}{
  .m-md-0{margin: 0}
  .m-md-1{margin: .25rem;}
  .m-md-2{margin: .5rem;}
  .m-md-3{margin: 1rem;}
  .m-md-4{margin: 1.25rem;}
  .m-md-5{margin: 1.5rem;}

  .ml-md-a{margin-left: auto;}
  .ml-md-0{margin-left: 0;}
  .ml-md-1{margin-left: .25rem;}
  .ml-md-2{margin-left: .5rem;}
  .ml-md-3{margin-left: 1rem;}
  .ml-md-4{margin-left: 1.25rem;}
  .ml-md-5{margin-left: 1.5rem;}

  .mr-md-a{margin-right: auto;}
  .mr-md-0{margin-right: 0;}
  .mr-md-1{margin-right: .25rem;}
  .mr-md-2{margin-right: .5rem;}
  .mr-md-3{margin-right: 1rem;}
  .mr-md-4{margin-right: 1.25rem}
  .mr-md-5{margin-right: 1.5rem;}

  .mt-md-a{margin-top: auto;}
  .mt-md-0{margin-top: 0;}
  .mt-md-1{margin-top: .25rem;}
  .mt-md-2{margin-top: .5rem;}
  .mt-md-3{margin-top: 1rem;}
  .mt-md-4{margin-top: 1.25rem}
  .mt-md-5{margin-top: 1.5rem;}

  .mb-md-a{margin-bottom: auto;}
  .mb-md-0{margin-bottom: 0;}
  .mb-md-1{margin-bottom: .25rem;}
  .mb-md-2{margin-bottom: .5rem;}
  .mb-md-3{margin-bottom: 1rem;}
  .mb-md-4{margin-bottom: 1.25rem}
  .mb-md-5{margin-bottom: 1.5rem;}
}

@media#{$large-up}{
  .m-lg-a{margin: auto;}
  .m-lg-0{margin: 0;}
  .m-lg-1{margin: .25rem;}
  .m-lg-2{margin: .5rem;}
  .m-lg-3{margin: 1rem;}
  .m-lg-4{margin: 1.25rem;}
  .m-lg-5{margin: 1.5rem;}

  .ml-lg-a{margin-left: auto;}
  .ml-lg-0{margin-left: 0;}
  .ml-lg-1{margin-left: .25rem;}
  .ml-lg-2{margin-left: .5rem;}
  .ml-lg-3{margin-left: 1rem;}
  .ml-lg-4{margin-left: 1.25rem;}
  .ml-lg-5{margin-left: 1.5rem;}

  .mr-lg-a{margin-right: auto;}
  .mr-lg-0{margin-right: 0;}
  .mr-lg-1{margin-right: .25rem;}
  .mr-lg-2{margin-right: .5rem;}
  .mr-lg-3{margin-right: 1rem;}
  .mr-lg-4{margin-right: 1.25rem}
  .mr-lg-5{margin-right: 1.5rem;}

  .mt-lg-a{margin-top: auto;}
  .mt-lg-0{margin-top: 0;}
  .mt-lg-1{margin-top: .25rem;}
  .mt-lg-2{margin-top: .5rem;}
  .mt-lg-3{margin-top: 1rem;}
  .mt-lg-4{margin-top: 1.25rem}
  .mt-lg-5{margin-top: 1.5rem;}

  .mb-lg-a{margin-bottom: auto;}
  .mb-lg-0{margin-bottom: 0;}
  .mb-lg-1{margin-bottom: .25rem;}
  .mb-lg-2{margin-bottom: .5rem;}
  .mb-lg-3{margin-bottom: 1rem;}
  .mb-lg-4{margin-bottom: 1.25rem}
  .mb-lg-5{margin-bottom: 1.5rem;}
}