.modal-shroud {
  @include stacking-context(modal);
  background-color: rgba(#39434f, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  &.hidden {
    display: none;
  }
}

.modal {
  background-color: white;
  position: fixed;
  z-index: 10;
  min-width: 300px;
  min-height: 200px;
  max-height: calc(90vh - 50px);
  max-width: 90vw;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  @media #{$medium-up} {
    min-width: 70vw;
  }

  @media #{$large-up} {
    min-width: 50vw;
    max-width: 1170px;
  }

  &.solo-modal {
    min-width: 95vw;

    @media #{$medium-up} {
      min-width: 550px;
    }

    @media #{$large-up} {
      min-width: 550px;
    }
  }

  &.video-modal, &.tour-modal {
    width: 100vw;
    height: calc(100vh - 50px);
    top: 50px;
    max-height: 100vh;
    max-width: 100vw;

    @media #{$medium-up} {
      width: 100%;
      height: auto;
      max-height: 75vh;
      max-width: 90vw;
      top: unset;
    }

    iframe {
      width:100%;
      height:100%;
      border:0;
      aspect-ratio: 1 / 1;

      @media #{$medium-up} {
        max-height: 75vh;
        aspect-ratio: 16 / 9;

      }
    }
  }
}

.modal-content {
  padding: 2rem;
  overflow: auto;
  z-index: 10;
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    max-height: 700px;
  }
}

.modal-close {
  position: absolute;
  top: 0;
  right: 0;
  background: $rhino;
  color: $gold;
  width: 44px;
  height: 44px;
  line-height: 44px;
  z-index: 20;
  font-size: 24px;
  text-align: center;
}
