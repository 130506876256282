.hero {
    @include stacking-context(null);
    min-height: rem-calc(500);

    &:not(.destination-map-outer-wrap):before{
        z-index: 1;
        position: absolute;
        content: " ";
        background: rgba($hero-overlay, 0.35);
        height: calc(4rem + 50px);
        width: 100%;
        bottom: 0;
        left: 0;
        background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.35) 75%,rgba(0,0,0,0.35) 100%);
    }
}

.hero--info{
    @include vertical-align($position: absolute);
    width: 90%;
    left: 5%;

    @media#{$large-up}{
        width: 60%;
        left: 20%;
    }

    > *:not(.button) {
        text-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
        color: $white;
    }

    h2 { line-height: 1.2em; }

    p {
        font-size: 120%;
        line-height: rem-calc(32);
        font-weight: 600;

        @media#{$medium-up}{
            font-size: 140%;
        }

        @media#{$small-only}{
            font-size: 100%;
            margin-bottom: 0;
        }
    }

    &.above-pull-up{
        margin-top: rem-calc(-50);
        @media#{$small-only}{
            margin-top: 0;
        }
    }
}

.hero--image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
    height: 100%;

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}