/* Open Sans - LOCAL */

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Open Sans'),
  local('open_sans'),
    /* from https://fonts.gstatic.com/s/open_sans/v13/cJZKeOuBrn4kERxqtaUH3fY6323mHUZFJMgTvxaG2iE.eot */ url('/fonts/open_sans/open_sans_400.eot?#iefix') format('embedded-opentype'),
    /* from https://fonts.gstatic.com/s/open_sans/v13/cJZKeOuBrn4kERxqtaUH3T8E0i7KZn-EPnyo3HZu7kw.woff */ url('/fonts/open_sans/open_sans_400.woff') format('woff'),
    /* from https://fonts.gstatic.com/s/open_sans/v13/cJZKeOuBrn4kERxqtaUH3VtXRa8TVwTICgirnJhmVJw.woff2 */ url('/fonts/open_sans/open_sans_400.woff2') format('woff2'),
    /* from https://fonts.gstatic.com/l/font?kit=cJZKeOuBrn4kERxqtaUH3Zbd9NUM7myrQQz30yPaGQ4&skey=62c1cbfccc78b4b2&v=v13#open_sans */ url('/fonts/open_sans/open_sans_400.svg#open_sans') format('svg'),
    /* from https://fonts.gstatic.com/s/open_sans/v13/cJZKeOuBrn4kERxqtaUH3aCWcynf_cDxXwCLxiixG1c.ttf */ url('/fonts/open_sans/open_sans_400.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Open Sans Light'),
  local('open_sans-Light'),
    /* from https://fonts.gstatic.com/s/open_sans/v13/DXI1ORHCpsQm3Vp6mXoaTXZ2MAKAc2x4R1uOSeegc5U.eot */ url('/fonts/open_sans/open_sans_300.eot?#iefix') format('embedded-opentype'),
    /* from https://fonts.gstatic.com/s/open_sans/v13/DXI1ORHCpsQm3Vp6mXoaTXhCUOGz7vYGh680lGh-uXM.woff */ url('/fonts/open_sans/open_sans_300.woff') format('woff'),
    /* from https://fonts.gstatic.com/s/open_sans/v13/DXI1ORHCpsQm3Vp6mXoaTegdm0LZdjqr5-oayXSOefg.woff2 */ url('/fonts/open_sans/open_sans_300.woff2') format('woff2'),
    /* from https://fonts.gstatic.com/l/font?kit=DXI1ORHCpsQm3Vp6mXoaTVlIn5tFQcqMuf-jhyJP0ps&skey=b33cc031a589c168&v=v13#open_sans */ url('/fonts/open_sans/open_sans_300.svg#open_sans') format('svg'),
    /* from https://fonts.gstatic.com/s/open_sans/v13/DXI1ORHCpsQm3Vp6mXoaTYnF5uFdDttMLvmWuJdhhgs.ttf */ url('/fonts/open_sans/open_sans_300.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local('Open Sans Light Italic'),
  local('open_sansLight-Italic'),
    /* from https://fonts.gstatic.com/s/open_sans/v13/PRmiXeptR36kaC0GEAetxrXcjzEax2LfQAlK8DdMzhA.eot */ url('/fonts/open_sans/open_sans_300italic.eot?#iefix') format('embedded-opentype'),
    /* from https://fonts.gstatic.com/s/open_sans/v13/PRmiXeptR36kaC0GEAetxh_xHqYgAV9Bl_ZQbYUxnQU.woff */ url('/fonts/open_sans/open_sans_300italic.woff') format('woff'),
    /* from https://fonts.gstatic.com/s/open_sans/v13/PRmiXeptR36kaC0GEAetxko2lTMeWA_kmIyWrkNCwPc.woff2 */ url('/fonts/open_sans/open_sans_300italic.woff2') format('woff2'),
    /* from https://fonts.gstatic.com/l/font?kit=PRmiXeptR36kaC0GEAetxj4dnM32POWavC8T_whbZeI&skey=78fa527f8e87b8b8&v=v13#open_sans */ url('/fonts/open_sans/open_sans_300italic.svg#open_sans') format('svg'),
    /* from https://fonts.gstatic.com/s/open_sans/v13/PRmiXeptR36kaC0GEAetxrfB31yxOzP-czbf6AAKCVo.ttf */ url('/fonts/open_sans/open_sans_300italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Open Sans Italic'),
  local('open_sans-Italic'),
    /* from https://fonts.gstatic.com/s/open_sans/v13/xjAJXh38I15wypJXxuGMBmfQcKutQXcIrRfyR5jdjY8.eot */ url('/fonts/open_sans/open_sans_400italic.eot?#iefix') format('embedded-opentype'),
    /* from https://fonts.gstatic.com/s/open_sans/v13/xjAJXh38I15wypJXxuGMBobN6UDyHWBl620a-IRfuBk.woff */ url('/fonts/open_sans/open_sans_400italic.woff') format('woff'),
    /* from https://fonts.gstatic.com/s/open_sans/v13/xjAJXh38I15wypJXxuGMBo4P5ICox8Kq3LLUNMylGO4.woff2 */ url('/fonts/open_sans/open_sans_400italic.woff2') format('woff2'),
    /* from https://fonts.gstatic.com/l/font?kit=xjAJXh38I15wypJXxuGMBnNsigHlcrQGNV8nkUSFQfc&skey=743457fe2cc29280&v=v13#open_sans */ url('/fonts/open_sans/open_sans_400italic.svg#open_sans') format('svg'),
    /* from https://fonts.gstatic.com/s/open_sans/v13/xjAJXh38I15wypJXxuGMBp0EAVxt0G0biEntp43Qt6E.ttf */ url('/fonts/open_sans/open_sans_400italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Open Sans Semibold'),
  local('open_sans-Semibold'),
    /* from https://fonts.gstatic.com/s/open_sans/v13/MTP_ySUJH_bn48VBG8sNSnZ2MAKAc2x4R1uOSeegc5U.eot */ url('/fonts/open_sans/open_sans_600.eot?#iefix') format('embedded-opentype'),
    /* from https://fonts.gstatic.com/s/open_sans/v13/MTP_ySUJH_bn48VBG8sNSnhCUOGz7vYGh680lGh-uXM.woff */ url('/fonts/open_sans/open_sans_600.woff') format('woff'),
    /* from https://fonts.gstatic.com/s/open_sans/v13/MTP_ySUJH_bn48VBG8sNSugdm0LZdjqr5-oayXSOefg.woff2 */ url('/fonts/open_sans/open_sans_600.woff2') format('woff2'),
    /* from https://fonts.gstatic.com/l/font?kit=MTP_ySUJH_bn48VBG8sNSllIn5tFQcqMuf-jhyJP0ps&skey=a162967ffaa5aa08&v=v13#open_sans */ url('/fonts/open_sans/open_sans_600.svg#open_sans') format('svg'),
    /* from https://fonts.gstatic.com/s/open_sans/v13/MTP_ySUJH_bn48VBG8sNSonF5uFdDttMLvmWuJdhhgs.ttf */ url('/fonts/open_sans/open_sans_600.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: local('Open Sans Semibold Italic'),
  local('open_sans-SemiboldItalic'),
    /* from https://fonts.gstatic.com/s/open_sans/v13/PRmiXeptR36kaC0GEAetxo8mkAiYpFywqG2RvpzBnTU.eot */ url('/fonts/open_sans/open_sans_600italic.eot?#iefix') format('embedded-opentype'),
    /* from https://fonts.gstatic.com/s/open_sans/v13/PRmiXeptR36kaC0GEAetxn5HxGBcBvicCpTp6spHfNo.woff */ url('/fonts/open_sans/open_sans_600italic.woff') format('woff'),
    /* from https://fonts.gstatic.com/s/open_sans/v13/PRmiXeptR36kaC0GEAetxl2umOyRU7PgRiv8DXcgJjk.woff2 */ url('/fonts/open_sans/open_sans_600italic.woff2') format('woff2'),
    /* from https://fonts.gstatic.com/l/font?kit=PRmiXeptR36kaC0GEAetxuVmfClkf66xvxL1SWm4y8M&skey=5addd15a3a8d014a&v=v13#open_sans */ url('/fonts/open_sans/open_sans_600italic.svg#open_sans') format('svg'),
    /* from https://fonts.gstatic.com/s/open_sans/v13/PRmiXeptR36kaC0GEAetxi8cqLH4MEiSE0ROcU-qHOA.ttf */ url('/fonts/open_sans/open_sans_600italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Open Sans Bold'),
  local('open_sans-Bold'),
    /* from https://fonts.gstatic.com/s/open_sans/v13/k3k702ZOKiLJc3WVjuplzHZ2MAKAc2x4R1uOSeegc5U.eot */ url('/fonts/open_sans/open_sans_700.eot?#iefix') format('embedded-opentype'),
    /* from https://fonts.gstatic.com/s/open_sans/v13/k3k702ZOKiLJc3WVjuplzHhCUOGz7vYGh680lGh-uXM.woff */ url('/fonts/open_sans/open_sans_700.woff') format('woff'),
    /* from https://fonts.gstatic.com/s/open_sans/v13/k3k702ZOKiLJc3WVjuplzOgdm0LZdjqr5-oayXSOefg.woff2 */ url('/fonts/open_sans/open_sans_700.woff2') format('woff2'),
    /* from https://fonts.gstatic.com/l/font?kit=k3k702ZOKiLJc3WVjuplzFlIn5tFQcqMuf-jhyJP0ps&skey=cd9e1a36bb25a3c3&v=v13#open_sans */ url('/fonts/open_sans/open_sans_700.svg#open_sans') format('svg'),
    /* from https://fonts.gstatic.com/s/open_sans/v13/k3k702ZOKiLJc3WVjuplzInF5uFdDttMLvmWuJdhhgs.ttf */ url('/fonts/open_sans/open_sans_700.ttf') format('truetype');
}

/* OLD STANDARD - LOCAL*/

@font-face {
  font-family: 'Old Standard TT';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Old Standard TT Regular'),
  local('OldStandardTT-Regular'),
    /* from https://fonts.gstatic.com/s/oldstandardtt/v7/n6RTCDcIPWSE8UNBa4k-DA8xXkqeKCK1Z-hKQQc_vz4.eot */ url('/fonts/old_standard/old_standard_tt_400.eot?#iefix') format('embedded-opentype'),
    /* from https://fonts.gstatic.com/s/oldstandardtt/v7/n6RTCDcIPWSE8UNBa4k-DAjWwMcLosnYS7_elQHKBmA.woff */ url('/fonts/old_standard/old_standard_tt_400.woff') format('woff'),
    /* from https://fonts.gstatic.com/s/oldstandardtt/v7/n6RTCDcIPWSE8UNBa4k-DG75SyxP8F_Nt1CRRMel2UU.woff2 */ url('/fonts/old_standard/old_standard_tt_400.woff2') format('woff2'),
    /* from https://fonts.gstatic.com/l/font?kit=n6RTCDcIPWSE8UNBa4k-DAcmInQMturxUF896HBJKrI&skey=f1cef4e9ada09fef&v=v7#OldStandardTT */ url('/fonts/old_standard/old_standard_tt_400.svg#OldStandardTT') format('svg'),
    /* from https://fonts.gstatic.com/s/oldstandardtt/v7/n6RTCDcIPWSE8UNBa4k-DLGMqOskSJahibCG-vhG9Ug.ttf */ url('/fonts/old_standard/old_standard_tt_400.ttf') format('truetype');
}

@font-face {
  font-family: 'Old Standard TT';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Old Standard TT Italic'),
  local('OldStandardTT-Italic'),
    /* from https://fonts.gstatic.com/s/oldstandardtt/v7/QQT_AUSp4AV4dpJfIN7U5IRPyX9vpc8RzMaNdWmGZuo.eot */ url('/fonts/old_standard/old_standard_tt_400italic.eot?#iefix') format('embedded-opentype'),
    /* from https://fonts.gstatic.com/s/oldstandardtt/v7/QQT_AUSp4AV4dpJfIN7U5KCfO6YLYlwZkGaKgYrym0o.woff */ url('/fonts/old_standard/old_standard_tt_400italic.woff') format('woff'),
    /* from https://fonts.gstatic.com/s/oldstandardtt/v7/QQT_AUSp4AV4dpJfIN7U5FQ6NBT6gvjJm6dP1MVY-i0.woff2 */ url('/fonts/old_standard/old_standard_tt_400italic.woff2') format('woff2'),
    /* from https://fonts.gstatic.com/l/font?kit=QQT_AUSp4AV4dpJfIN7U5NM5_dHk7QNiyVuCXN_HEo4&skey=308f26f9fad19839&v=v7#OldStandardTT */ url('/fonts/old_standard/old_standard_tt_400italic.svg#OldStandardTT') format('svg'),
    /* from https://fonts.gstatic.com/s/oldstandardtt/v7/QQT_AUSp4AV4dpJfIN7U5FwRGS1J0uDbqVT85dREjI0.ttf */ url('/fonts/old_standard/old_standard_tt_400italic.ttf') format('truetype');
}

