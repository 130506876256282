.line-driver {
  @include stacking-context(default);
  display: flex;
  height: 100px;
  align-items: center;
  justify-content: center;

}

/*.line-driver--background {
  position: absolute;
  top: 50%;
  left: 50%;
  min-height: 100%;
  width: 100%;
  opacity: 0.7;
  transform: translate(-50%, -50%);
  z-index: 1;
  background-color: $dark-slate;
}*/

.line-driver--image {
  height: 40px;
}