.full-width {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  max-width: initial;

  .columns {
    padding-left: 0;
    padding-right: 0;
  }
}

.w-50 {
  width: 50%;
}

@media#{$small-only}{
  .full-width-sm-only {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    max-width: initial;

    .columns {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

@media#{$medium-only}{
  .full-width-md-only {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    max-width: initial;

    .columns {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

@media#{$medium-up}{
  .full-width-md {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    max-width: initial;

    .columns {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

@media#{$large-up}{
  .full-width-lg {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    max-width: initial;

    .columns {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
