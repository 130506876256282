// nested selectors to increase specificty as some styles were being overridden by global legacy styles.
.header {
    @include stacking-context(header, "infront");
    height: 70px;
    background-color: white;
    padding: 15px 5px;
    border-bottom: 1px solid $very-light-gray;

    @media #{$medium-up} {
        padding: 20px 0;
        height: 90px;
    }

    @media #{$large-up} {
        padding: 15px 0;
        height: auto;
    }
}

.header-logo{
    width: 90px;

    @media #{$large-up} {
        width: 234px;
    }

    img {
        height: 40px;
        width: 90px;

        @media #{$large-up} {
            height: 60px;
            width: 234px;
            margin-bottom: 0;
        }
    }
}

.header--wrap {
    flex: 1;
    justify-content: space-between;

    @media #{$medium-up} {
        padding-left: 2rem;
    }

    @media #{$large-up} {
        justify-content: flex-end;
    }
}

.header--contact-info {
    display: none;
    position: relative;
    text-align: left;
    order: 1;

    @media #{$small-up} {
        display: block;
    }

    @media #{$large-up} {
        order: 4;
        text-align: right;
        padding-left: 15px;
    }
}

.header--account-menu.open,
.header--contact-menu.open  {
    transition: 400ms all;
    max-height: 600px;
    opacity: 1;
    padding: 10px 20px;
}

.header--account-mobile-toggle,
.header--contact-mobile-toggle {
    display: flex;
    flex-direction: column;
    height: 35px;
    justify-content: space-between;
    order: 2;

    .icn {
        color: $gold;
    }
}

.header--account-menu,
.header--contact-menu {
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
    background-color: $rhino;
    color:white;
    padding: 0 20px;
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: 100ms all;

    @media #{$medium-up} {
        top: 90px
    }

    @media #{$large-up} {
        top: 100%;
    }

    .fa-phone {
        color: #69c365;
    }
}

.header--account-menu-link,
.header--contact-menu-link {
    white-space: nowrap;
    color: white;
    line-height: 50px;
    display: flex;
    align-items: center;
    width: 100%;
    min-width: 320px;
    font-weight: 400;
    letter-spacing: 0.4px;
    margin-bottom: 0;

    i {
        margin-left: auto;
        font-size: 14px;
    }

    & + .header--account-menu-link {
        border-top: 1px solid rgba(255,255,255, 0.1);
    }

    & + .header--contact-menu-link {
        border-top: 1px solid rgba(255,255,255, 0.1);
    }
}

.header--call-us {
    width: 100%;
    font-weight: 300;
    margin: 0;
    display: none;
    align-items: baseline;

    @media #{$medium-up} {
        display: flex;
        font-size: 15px;
        line-height: 30px;
        max-height: 30px;
    }

    @media #{$large-up} {
        font-size: 16px;
        width: 260px;
        height: 30px;
    }

    .InfinityNumber {
        font-weight: 600;
        color: black;
        min-width: 150px;

        @media #{$medium-up} {
            font-size: 22px;
            height: 30px;
        }

        @media #{$large-up} {
            font-size: 24px;
            width: 165px;
        }

        &:hover {
            color: $gold;
        }

        a {
            color: inherit;
        }
    }
}

.header--opening-time {
    width: 100%;
    font-size: 11px;
    margin: 0;
    display: none;
    min-height: 10px;

    @media #{$medium-up} {
        display: block;
        line-height: 10px;
        font-size: 10px;
    }

    @media #{$large-up} {
        font-size: 11px;
        line-height: 15px;
    }
}

.header--cruise-search {
    background-color: $gold;
    height: 40px;
    min-width: 40px;
    line-height: 40px;
    color: white;
    font-size: 15px;
    padding: 0;
    text-align: center;
    order: 3;

    span {
        display: none;
    }

    @media #{$medium-up} {
        height: 50px;
        min-width: 50px;
        line-height: 50px;
    }

    @media screen and (min-width: 750px) {
        padding: 0 30px;
        span {
            display: inline-block;
        }
    }

    @media #{$large-up} {
        display: none;
    }
}

.header--burger-menu {
    color: black;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    padding-left: 10px;
    padding-top: 4px;
    align-items: center;
    justify-items: center;
    order: 4;

    @media #{$large-up} {
        display: none;
    }
}

.header--account,
.header--shortlist,
.header--newsletter {
    display: none;
    position: relative;
    font-size: 14px;
    color: $charcoal;
    padding: 0 15px;
    width: auto;
    flex-direction: row;
    align-items: center;
    font-weight: bold;

    &:hover {
        color: $gold;
    }

    @media #{$large-up} {
        display: flex;
    }
}

.header--shortlist {
    width: 110px;
}


.header--site-search-wrapper {
    display: block;
    height: 50px;
    width: 100%;
    border: 1px solid $very-light-gray;
    border-radius: 25px;
    padding: 13px 10px 15px 15px;
    background-color: white;
    margin: 20px 0 40px;

    @media #{$large-up} {
        width: 230px;
        margin: 0;
    }
}

.header--site-search-input {
    display: inline-block;
    height: 20px;
    width: calc(100% - 25px);
    line-height: 20px;
    color: black;
    border: none;
    border-left: 1px solid $very-light-gray;
    margin: 0;
    box-shadow: none;
    font-size: 1rem;
    padding: 0 0 0 9px;

    &::placeholder {
        color: $dark-gray;
        font-weight: 300;
    }

    &:focus, &:focus-within {
        border: none;
        outline: none;
        box-shadow: none;
    }
}

.header--site-search-button {
    display: inline-block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    color: $charcoal;
}


/** TO BE MOVED TO NEW FILES!!!!!!! **/

.upper-message-bar {
    position: relative;
    z-index: 310;
    background: $rhino;
    padding: 10px 40px 10px 10px;
    font-size: 0.8rem;

    @media #{$large-up} {
        font-size: inherit;
        padding: 10px 0;
    }

    &.hide {
        display: none;
    }

    h2,h3,h4,h5,
    p{
        margin-bottom: 0;
        color: $white;
    }

    .close{
        position: absolute;
        display: block;
        height: 16px;
        width: 16px;
        right: 15px;
        z-index: 20;
        cursor: pointer;
        top: 50%;
        transform: translateY(-50%);

        &:before,
        &:after{
            position: absolute;
            background: $tan;
            display: block;
            content: "";
            height: 20px;
            width: 2px;
        }
        &:before{
            -ms-transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            left: 7px;
            top: -2px;
        }
        &:after{
            -ms-transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
            left: 7px;
            top: -2px;
        }
    }
    h2,h3,h4,h5{
        margin-bottom: 5px;
        @media#{$large-up}{
            margin-bottom: 0;
            padding: 2px 0;
            font-size: 36px;
        }
    }

    img {
        float: left;
        margin-top: 0px;
        margin-right: 10px;
        width: 25px;
        @media#{$medium-only}{
            width: auto;
        }
        @media#{$large-up}{
            margin-top: 4px;
            width: auto;
        }
    }

    .newsletter-form{
        padding: 10px 0;
        text-align: center;

        @media #{$large-up}{
            padding-bottom: 0;
        }

        p{
            display: inline-block;
            vertical-align: top;
            font-family: $header-font-family;
            margin-bottom: 0;
            font-style: italic;
            font-size: 20px;
            color: $tan;
            line-height: 39px;
            height: 39px;
            white-space: nowrap;
            width: 100%;

            @media #{$medium-up}{
                width: auto;
                padding-right: 1rem;
            }
        }
        form{
            text-align: center;

            @media #{$medium-up} {
                text-align: left;
            }

            input {
                margin-bottom: 0px;
                width: calc(100% - 95px);
                display: block;
                float:left;


                @media #{$large-up}{
                    width: calc(100% - 119px - 2rem);
                }
            }
            .button {
                border: none;
                margin-bottom: 0;
                border: 0;
                float:left;
                display: block;
                padding: 0 1rem ;

                @media #{$large-up}{
                    padding: 0 2rem ;
                }
            }

            .privacy-policy-link {
                top: -6px;
                position: relative;
                line-height: 40px;
                vertical-align: top;
                display: inline-block;
            }
        }
        a.close{
            top: 14px;
            right: 10px;
        }
    }
}

.newsletter-signup--wrapper{
    width: 100%;
    display: inline-block;
    height: 40px;
    max-width: 460px;
    &:after {
        content: ' ';
        display: block;
        clear: both;
    }

}

.form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media#{$medium-up}{
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

}

.privacy-policy-link {
    font-size: 0.7rem;
    line-height: 20px;
    color: white;
    white-space: nowrap;

    @media#{$medium-up}{
        line-height: 39px;
        height: 39px;
    }
}

