$active-bg: $navy;
$active-label-colour: white;

$inactive-bg: #d3d7dc;
$inactive-label-colour: black;

$toggle-bg: white;
$border: $medium-gray;
$transition-time: 0.15s;
$transition-type: ease-out;
$active-label-colour: white;


.toggle {
  position: relative;
  display: inline-flex;
  margin: 0;

  @include stacking-context(default);
}

.toggle--input{
  display: none;
}

.toggle--input + label.toggle--container {
  z-index: 1;
  width: 100%;
  margin: 0;
  position: relative;
  border-radius: 20px;
  height: 35px;
  border: 1px solid $border;
  background-color: $inactive-bg;
  padding: 0 10px;
  padding-left: 35px;
  cursor: pointer;
  user-select: none;
}

.toggle--label {
  opacity: 0;
  line-height: 35px;
  font-family: sans-serif;
  text-transform: uppercase;
  text-align: center;
  pointer-events: none;
  color: $inactive-label-colour;
  font-weight: 400;
  font-size: 12px;
  min-width: 28px;
  display: inline-block;
  transition: $transition-time opacity $transition-type;

  & + .toggle--label {
    opacity: 1;
  }
}

.toggle--container:after {
  content: '';
  z-index: 4;
  position: absolute;
  width: 27px;
  height: 27px;
  top: 3px;
  right: calc(100% - 31px);
  border-radius: 25px;
  background-color: $toggle-bg;
  transition: $transition-time all $transition-type;
}

.toggle--container:focus {
  outline: none;

   &:before {
     content: ' ';
     position: absolute;
     left: -3px;
     top: -3px;
     border-radius: 20px;
     height: calc(100% + 6px);
     width: calc(100% + 6px);
     border: 1px solid $gold;
   }
 }

.toggle--input:checked + .toggle--container  {
  background-color: $active-bg;
  padding-right: 35px;
  padding-left: 10px;

  .toggle--label {
    opacity: 1;
    color: $active-label-colour;
  }

  .toggle--label + .toggle--label {
    opacity: 0;
  }

  &:after {
    right: 3px;
  }
}