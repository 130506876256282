.site-navigation {
  @include stacking-context(header);
  position: absolute;
  top: auto;
  left: 0;
  width: 100%;
  max-height: 0;
  overflow: hidden;
  background-color: $rhino;
  color: $white;
  transition: 100ms all;
  opacity: 0;

  @media #{$large-up} {
    position: relative;
    background-color: white;
    height: 60px;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, .1);
    opacity: 1;
    max-height: none;
    overflow: visible;
  };

  &.open {
    max-height: 2200px;
    transition: 400ms all;
    opacity: 1;

    @media #{$large-up} {
      max-height: none;
    }
  }

}

.site-navigation--column {
  display: flex;
  flex-direction: column;

  @media #{$large-up} {
    flex-direction: row;
  }
}

.site-navigation--items {
  list-style: none;
  display: inline-block;
  line-height: 60px;
  margin: 0;
  width: 100%;

  @media #{$large-up}{
    margin-left: -15px;
    margin-right: auto;
  }
}

.site-navigation--item {
  display: block;
  padding: 0 10px;

  @media #{$large-up}{
    padding: 0;
    display: inline-block;
  }

  & + & {
    border-top: 1px solid rgba(255,255,255,0.1);
  }

  &_small {
    position: relative;
  }
}

.site-navigation--link {
  display: flex;
  align-items: center;
  line-height: 50px;
  text-align: left;
  width: 100%;
  font-size: 14px;
  color: white;
  padding: 0;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 0.4px;
  margin: 0;

  @media (min-width: 1070px){
    padding: 0 15px;
  }

  @media #{$large-up}{
    display: block;
    color: black;
    text-align: center;
    line-height: 60px;
    padding: 0 11px;
  }

  > i {
    transition: 500ms all;
    position: relative;
    top: -1px;
    color: white;
    font-size: 12px;
    margin-left: auto;
    width: 12px;
    height: 12px;

    @media #{$large-up}{
      color: $gold;
    }
  }
}



.site-navigation--call-button, .site-navigation--search-button  {
  display: none;
  line-height: 60px;
  height: 60px;
  margin: 0;
  border: 0;
  font-size: 14px;
  text-transform: uppercase;

  @media #{$large-up} {
    display: inline-block;
    white-space: nowrap;
  }
}

.site-navigation--call-button {
  position: relative;
  margin-left: auto;
  padding: 0 20px;
  text-align: center;
  min-width: 205px;
  transition: background-color 300ms;
  background-color: $gold;
  color: white;

  &:hover {
    color: white;
    background-color: $dark-blue;
  }

  &-white {
    background-color: white;
    color: $black;
  }

  &.small {
    opacity: 0;
    line-height: 40px;
    height: 40px;
    transition: opacity 0.3s ease;
  }
}

.site-navigation--search-button {
  background-color: $gold;
  color: white;
  text-align: center;
  min-width: 60px;

  @media screen and (min-width: 1190px) {
    min-width: 230px;
  }

  span  {
    display: none;

    @media screen and (min-width: 1190px) {
      display: inline-block;
    }
  }
}

.site-navigation--mega-menu{
  z-index: -1;
  top: 100%;
  left: 0;
  background-color: #fbfbfb;
  box-shadow: inset 0 10px 20px -10px rgba(0,0,0,0.1);
  max-height: 0;
  overflow: hidden;
  transition: 100ms all;
  opacity: 0;
  margin-left: -20px;
  width: calc(100% + 40px);
  padding: 0 20px;

  @media #{$medium-up} {
    padding: 0 25px;
    margin-left: -25px;
    width: calc(100% + 50px);
  }

  @media #{$large-up} {
    position: absolute;
    width: 100%;
    margin: 0;
  }
}

.site-navigation--small-menu{
  z-index: -1;
  top: 100%;
  left: 0;
  background-color: #fbfbfb;
  box-shadow: inset 0 10px 20px -10px rgba(0,0,0,0.1);
  max-height: 0;
  overflow: hidden;
  transition: 100ms all;
  opacity: 0;
  margin-left: -20px;
  width: calc(100% + 40px);
  padding: 0 20px;

  display: flex;
  flex-direction: column;
  color: black;

  @media #{$large-up} {
    position: absolute;
    box-shadow: 0 10px 20px -10px rgba(0,0,0,0.1);
    background-color: white;
    width: 240px;
  }
}

.site-navigation--mega-menu-row {
  @include grid-row()
}

.site-navigation--mega-menu-nav {
  list-style: none;
  margin: 0;
}

.site-navigation--mega-menu-heading {
  font-size: 26px;
  margin: 20px 0;
}

.site-navigation--mega-menu-nav-item {
  display: inline-block;
  width: 100%;
  margin: 10px 0;
  color: black;

  @media screen and (min-width: 600px) {
    width: 49%;

    &.full-width {
      width: 100%;
    }
  }

  &:hover {
    color: $gold;
  }
}

.site-navigation--mega-menu-nav-item a {
  color: inherit;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0.3px;
}

.site-navigation--item:hover  {
  @media #{$large-up} {
    .site-navigation--link > i {
      transform: rotate(180deg);
      transform-origin: center center;
    }

    & .site-navigation--mega-menu {
      transition: 400ms all;
      max-height: 600px;
      padding: 30px 0;
      opacity: 1;
    }

    & .site-navigation--small-menu {
      transition: 400ms all;
      max-height: 600px;
      opacity: 1;
    }

    .site-navigation--link {
      color: $gold;
    }
  }
}

.site-navigation--item.open  {
  .site-navigation--link > i {
    transform: rotate(180deg);
    transform-origin: center center;
  }

  & .site-navigation--small-menu {
    transition: 400ms all;
    max-height: 1400px;
    opacity: 1;

    @media #{$large-up} {
      max-height: 600px;
    }
  }

  & .site-navigation--mega-menu {
    transition: 400ms all;
    max-height: 1400px;
    padding: 10px;
    opacity: 1;

    @media #{$large-up} {
      padding: 30px 0;
      max-height: 600px;
    }
  }
}

.site-navigation--small-menu-link {
  color: black;
  border-bottom: 1px solid $medium-gray;

  i {
    color: $gold;
    font-size: 12px;
  }

  &:last-of-type {
    border-bottom: none;
  }

  &:hover {
    color: $gold;
  }
}

