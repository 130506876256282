h1, h2, h3, h4, h5, h6 {
  line-height: 1.3;

  &.normal {
    font-style: normal;
  }

  &.italic {
    font-style: italic;
  }

  &.underline:after {
    content: ' ';
    width: 80px;
    height: 1px;
    background-color: $gold;
    display: block;
    margin: 17px 0;
  }

  &.underline {
    &.text-center, &.text-md-center, &.text-lg-center {
      &::after {
        margin: 17px auto;
      }
    }

    &.text-lg-left:after {
      @media #{$large-up} {
        margin: 17px 0;
      }
    }
  }
}

h1 {
  &.small {
    font-size: 2.4rem;
  }
}

span.superscript {
  vertical-align: super;
  font-size: 16px;
}

span.sub-title {
  display: block;
  clear: both;
  font-size: 16px;
  line-height: 1.8;
  letter-spacing: 0.3px;
  font-weight: 300;
}

P.updated {
  font-size: 16px;
  line-height: 1.8;
  letter-spacing: 0.3px;
  font-weight: 300;
}

.updated {
  ul, p, ol {
    font-size: 16px;
    line-height: 1.8;
    letter-spacing: 0.3px;
    font-weight: 300;
  }
}

.label {
  font-size: 14px;
}

pre {
  background: #f4f4f4;
  border: 1px solid #ddd;
  border-left: 3px solid #f36d33;
  color: #666;
  page-break-inside: avoid;
  font-family: monospace;
  font-size: 15px;
  line-height: 1.6;
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  padding: 1em 1.5em;
  display: block;
  word-wrap: break-word;

  code {
    display: inline-block;
    border: none;
    color: inherit;
    background-color: transparent;
  }
}

hr.rope {
  width: 100%;
  height: 4px;
  margin: 0.5rem 0;
  border: none;
  //background-image: repeating-linear-gradient(-45deg, transparent 0, transparent 1px, #dbdbdb 2px, #dbdbdb 3px, transparent 4px, transparent 5px);
  background-image: repeating-linear-gradient(-45deg, transparent 0, transparent 2px, #dbdbdb 2px, #dbdbdb 4px, transparent 4px, transparent 4px);

  &.large {
    margin: 1.5rem 0;
  }

  &.no-margin {
    margin: 0;
  }
}

span.tag {
  display: inline-block;
  background-color: $dark-red;
  font-weight: 400;
  color: white;
  min-width: 36px;
  height: 36px;
  border-radius: 18px;
  text-align: center;
  font-size: 16px;
  line-height: 36px;
  padding: 0 10px;
  white-space: nowrap;

  &.small {
    height: 25px;
    line-height: 25px;
    border-radius: 13px;
    padding: 0 8px;
    font-size: 12px;
  }

  &.gold {
    background-color: $gold;
  }
}

ul.info-list {
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 0;
  font-weight: 200;

  li {
    line-height: 1.6;
  }

  .info-list--item {
    display: block;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    margin: 10px 0;
    width: 50%;
    position: relative;

    @media #{$medium-up} {
      width: 33%;
    }

    @media #{$large-up} {
      width: 25%;
      font-size: 18px;
      line-height: 26px;
    }

    &.wide {
      width: 100%;

      @media #{$large-up} {
        width: 50%;
      }
    }

    &.icon {
      padding-left: 50px;
    }
  }

  .info-list--item-title {
    width: 100%;
    font-weight: 200;
    display: block;

    &:after { content: ':'}
  }

  .info-list--icon {
    position: absolute;
    top: 0;
    left: 20px;
    color: $gold;
    top: 4px;
  }
}

ul.check-list {
  list-style: none;
  flex-direction: column;
  margin-left: 0;
  font-weight: 200;

  li {
    position: relative;
    margin-left: 30px;
    line-height: 1.6;
  }

  li + li {
    margin-top: 1rem;
  }
}


ul.check-list li:before {
  content: '';
  display: inline-block;
  height: 14px;
  width: 5px;
  transform: rotate(45deg);
  border-bottom: 1px solid;
  border-right: 1px solid;
  position: absolute;
  left: -25px;
  top: 0.25rem;
}

.no-wrap {
  white-space: nowrap;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-underline {
  text-decoration: underline;
}


@media#{$medium-up}{
  .text-md-left {
    text-align: left;
  }

  .text-md-right {
    text-align: right;
  }

  .text-md-center {
    text-align: center;
  }

  .columns-lg-count-2 {
    column-count: 2;
    column-gap: 30px;
  }
}

@media#{$large-up}{
  .text-lg-center {
    text-align: center;
  }

  .text-lg-left {
    text-align: left;
  }

  .text-lg-right {
    text-align: right;
  }
}

.font-family-body {
  font-family: $body-font-family;
  font-style: normal;
}

.font-style-normal {
  font-style: normal;
}

.font-style-bold {
  font-weight: 600;
}

.font-style-super-bold {
  font-weight: 800;
}

.uppercase {
  text-transform: uppercase;
}


.bg-image-anchor-text {
  text-indent: -999999px;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  height: 100%;
  width: 100%;
}
