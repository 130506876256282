.blog-related-driver {
  display: block;
  position: relative;
  margin-top: 0;
  background-color: white;
  flex-direction: column;
  text-align: center;
  border: 1px solid transparent;
  padding-bottom: 50px;
  margin-bottom: 1rem;
  @media #{$medium-up} {
    min-height: 700px;
  }

  @media #{$large-up} {
    min-height: 610px;
  }

  &:hover {
    border: 1px solid $gold;
  }

  &--image {
    position: relative;
    background-color: $slate-navy;
    margin-bottom: 1rem;

    &:before {
      display: block;
      content: "";
      width: 100%;
      padding-top: (43 / 60) * 100%;
    }

    picture {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  &--title {
    line-height: 32px;
    padding: rem-calc(0 15);
    font-size: rem-calc(26);

    &:after {
      content: "";
      width: 20%;
      height: 1px;
      display: block;
      margin: 1.7rem auto;
      background-color: $navy;
      clear: both;
    }
  }

  &--info {
    line-height: 24px;
    padding: rem-calc(0 15);
    color: $light-navy;
  }


  &--date{
    font-family: $header-font-family;
    font-style: italic;
    margin-bottom: 0.5rem;
  }

  &--link {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    margin: 0;
  }
}