.checkbox {
  height: 50px;
  line-height: 50px;
  margin: 10px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &.small {
    height: 20px;
    line-height: 20px;

    &.m-top-sm {
      margin-top: -5px;
    }

    &.m-top-lg {
      margin-top: -10px;
    }

    .checkbox--box {
      height: 20px;
      width: 20px;
    }

    .checkbox--label {
      font-size: 12px;
      margin-top: 0;
    }
  }
}

.checkbox input {
  display: none;

  &:focus {
    outline: none;
  }
}

.checkbox:focus .checkbox--box, .checkbox input:focus +  .checkbox--box {
  outline: $gold solid 1px;
}

.checkbox--box {
  height: 30px;
  width: 30px;
  margin-right: 10px;
  background-color: white;
  border: 1px solid $light-gray;
  text-align: center;
  line-height: 20px;
  transition: all 300ms;
}

.checkbox--label {
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.5px;
  line-height: 16px;
  width: calc(100% - 40px);
  margin: 0;
}

@media #{$large-up} {
  .checkbox input:not(:checked):hover + .checkbox--box:before {
    content: '';
    height: 6px;
    width: 12px;
    position: relative;
    display: block;
    border-bottom: 2px solid $medium-gray;
    border-left: 2px solid $medium-gray;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

.checkbox input:checked + .checkbox--box {
  background-color: $gold;
  border-color: white;
  color: white;

  &:before {
    content: '';
    height: 6px;
    width: 12px;
    position: relative;
    display: block;
    border-bottom: 2px solid white;
    border-left: 2px solid white;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

.checkbox input:checked + .checkbox--label {
  font-weight: 500;
}