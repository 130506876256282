.d-block {display: block !important;}
.d-inline-block {display: inline-block !important;}
.d-inline {display: inline !important;}

@media#{$medium-up}{
    .d-md-block {display: block !important;}
    .d-md-inline-block {display: inline-block !important;}
    .d-md-inline {display: inline !important;}
}

@media#{$large-up}{
    .d-lg-block {display: block !important;}
    .d-lg-inline-block {display: inline-block !important;}
    .d-lg-inline {display: inline !important;}
}

