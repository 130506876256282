.sticky-telephone {
    min-width: 250px;
    line-height: 1;
    color: $black;
    position: fixed;
    top: 0;
    right: calc(50% - 40rem + 1rem);
    z-index: 100;
    display: block;
    background-color: $white;
    padding: 0.5rem 1rem 0.75rem;
    box-shadow: 1px 1px 10px #d4d4d4;

    @media screen and (max-width: 1200px) {
        right: 1rem;
    }

    p {
        margin-bottom: 0;
    }

    &--number {
        font-weight: 700;
        font-size: 1.3rem;
    }

    &--opening-hours {
        font-size: 0.65rem;
    }

    &--hidden {
        display: none;
        opacity: 0;
        transition: opacity 0.3s ease;
    }
}