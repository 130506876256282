.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.loading-title {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 0;
}

.loading-message {
  margin-top: 5px;
  font-size: 12px;
}

.spinner {
  margin: 0 1rem;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
  display: block;
  white-space: nowrap;

  div {
    background-color: #333;
    height: 100%;
    width: 5px;
    margin: 1px;
    display: inline-block;

    -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
    animation: sk-stretchdelay 1.2s infinite ease-in-out;
  }

  .rect2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
  }

  .rect3 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
  }

  .rect4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }

  .rect5 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
  }
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% {
       transform: scaleY(1.0);
       -webkit-transform: scaleY(1.0);
     }
}

.loader {
  border-radius: 50%;
  animation: spin 2s linear infinite;
  user-select: none;
  pointer-events: none;

  &--sm {
    width: 30px;
    height: 30px;
    border: 4px solid $light-gray;
    border-top: 4px solid $gold;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}