.newsletter-signup {
  text-align: center;
  padding: 1rem;

  @media#{$large-up}{
    display: flex;
    width: 100%;
  }

  &--label {
    font-size: rem-calc(20);
    display: inline-block;
    width: 100%;
    text-align: center;
    font-family: $header-font-family;
    font-style: italic;
    line-height: rem-calc(32);
    color: $white;
    margin-bottom: 1rem;

    @media#{$large-up}{
      font-size: rem-calc(24);
      line-height: rem-calc(40);
      padding-right: 15px;
      margin-bottom: 0;
      text-align: right;
      width: auto;
    }
  }

  &--form {
    position: relative;
    display: inline-block;
    width: 100%;

    @media#{$large-up}{
      padding-left: 15px;
      flex-grow: 1;
      width: auto;
    }

    input[type=email] {
      color: $white !important;
      display: inline-block;
      width: calc(100% - 60px);
      margin: 0 10px 0 0;
      height: 40px;
      background: none !important;
      border: none;
      border-bottom: rem-calc(3) solid $gold !important;
      box-shadow: none;
      border-radius: 0;

      &:focus {
        border: 0;
        outline: 0;
      }

      .is-valid-input {
        border-bottom: rem-calc(3) solid $success;
      }

      &.is-invalid-input {
        border-bottom: rem-calc(3) solid $alert;
      }
    }

    .form-error.is-visible {
      position: absolute;
      top: 40px;
      left: 15px;
      padding: 5px 10px;
      color: $alert;
      width: 100%;
      text-align: left;
      margin: 0 !important;
    }

    button {
      background: $gold;
      width: rem-calc(40);
      height: rem-calc(40);
      margin-bottom: 0;
      border: none;
      color: $white;
      text-transform: uppercase;
      padding: 0;
    }
  }


}

.newsletter-signup_header {
  position: relative;

  .form-error.is-visible {
    position: absolute;
    top: 60px;
    left: 0;
    padding: 5px 10px;
    color: white;
    z-index: 30000;
    background-color: $alert;
    width: calc(100% - 2rem);
    text-align: left;
    margin: 0 !important;

    &:before {
      content: '';
      position: absolute;
      left: 30px;
      top: -10px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 10px 10px 10px;
      border-color: transparent transparent $alert transparent;
    }
  }
}