.column .nopadding-right, .columns .nopadding-right {
  padding-right: 0;
}

.hero-gallery, .hero-video-gallery {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  z-index: 50;

  & > .button{
    margin: 0;

    @media #{$small-only} {
      text-indent: 10000px;
      overflow: hidden;
      width: 50px;
      position: relative;

      &:after {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        content: ' ';
        background-image: url("/images/camera.svg");
        background-position: 50% 50%;
        background-size: 60%;
        background-repeat: no-repeat;
        margin: 0 0 0 1px !important;
      }
    }
  }

  &.show-camera {
    & > .button {
      margin: 0;
      text-indent: 10000px;
      overflow: hidden;
      width: 50px;
      position: relative;

      &:after {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        content: ' ';
        background-image: url("/images/camera.svg");
        background-position: 50% 50%;
        background-size: 60%;
        background-repeat: no-repeat;
        margin: 0;
      }
    }
  }
}

.hero-video-gallery {
  right: calc(2rem + 157px);

  @media #{$small-only} {
    right: calc(2rem + 50px);

    & > .button {
      &:after {
        background-image: url("/images/video-camera.svg");
        background-size: 70%;
        margin-left: 1px;
      }
    }
  }

  &.show-camera {
    & > .button {
      &:after {
        background-image: url("/images/video-camera.svg");
        background-size: 70%;
      }
    }
  }

}

.years-logo img {
   max-width: 80%;
   max-height: 80px;
   margin: 20px auto 50px;
   display: block;
 }

.content-area iframe {
  max-width: 100%;
}

.extra-container {
  &.extra-center{
    .equal-height{
      margin: auto;
      float: none;
      text-align: center;
      -webkit-justify-content: center;
      justify-content: center;
    }
  }
  .extra-border {
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    display: flex;
    display: -webkit-flex;
    -webkit-box-align: center;
    -webkit-flex-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    background: $dark-blue;
    width: 100%;
    min-height: 64px;

    @media#{$small-only}{
      margin-bottom: 10px;
    }
    p.extra {
      text-transform: uppercase;
      width: 100%;
      padding: .75rem 1rem;
      margin-bottom: 0;
      text-align: center;
      color: $white;
    }
  }
}

.promotion_area {
  .hero-gallery, .hero-video-gallery {
    top: -4rem;
    //@media #{$small-only} {
    //  top: -4rem;
    //}
  }
}


.InfinityNumber {
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  font-weight: inherit;
}