.solo-modal {
    @media #{$small-only} {
        min-width: 95vw;
    }

    h3, p{
        margin-bottom: 1.8rem;
    }

    .cta-row {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @media #{$large-up} {
            flex-direction: row;
        }

        .button{
            margin-right: 1rem;
            margin-bottom: 1rem;

            @media screen and (min-width: 1024px) {
                margin-bottom: 0;
            }
        }
    }

    @media screen and (min-width: 40em) {
        &.reveal {
            width: 700px;
            max-width: 80rem;
        }
    }

}