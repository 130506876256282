.w-100 { width: 100%; }
.w-90 { width: 90%; }
.w-80 { width: 80%; }
.w-70 { width: 70%; }
.w-60 { width: 60%; }
.w-50 { width: 50%; }
.w-40 { width: 40%; }
.w-30 { width: 30%; }
.w-20 { width: 20%; }
.w-10 { width: 10%; }

@media#{$medium-up}{
  .w-md-100 { width: 100%; }
  .w-md-90 { width: 90%; }
  .w-md-80 { width: 80%; }
  .w-md-70 { width: 70%; }
  .w-md-60 { width: 60%; }
  .w-md-50 { width: 50%; }
  .w-md-40 { width: 40%; }
  .w-md-30 { width: 30%; }
  .w-md-20 { width: 20%; }
  .w-md-10 { width: 10%; }
}

@media#{$large-up}{
  .w-lg-100 { width: 100%; }
  .w-lg-90 { width: 90%; }
  .w-lg-80 { width: 80%; }
  .w-lg-70 { width: 70%; }
  .w-lg-60 { width: 60%; }
  .w-lg-50 { width: 50%; }
  .w-lg-40 { width: 40%; }
  .w-lg-30 { width: 30%; }
  .w-lg-20 { width: 20%; }
  .w-lg-10 { width: 10%; }
}